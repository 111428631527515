import remove from 'lodash/remove'
import Vue from 'vue'

const state = () => ({
  step: 1,
  steps: [
    { id: 1, name: 'info', active: true },
    { id: 2, name: 'location', active: false },
    // { id: 3, name: 'options', active: false },
    { id: 4, name: 'finish', active: false }
  ],
  id: null,
  offer: {},
  options: [],
  selectedOptions: [],
  stripe: {
    paymentIntentSecret: null
  },
  price: 0
})

const getters = {
  getStep: state => {
    return state.step
  },
  getPaymentIntentSecret: state => {
    return state.stripe.paymentIntentSecret
  },
  getOptions: state => {
    return state.options
  },
  getSelectedOptions: state => {
    return state.selectedOptions
  },
  getPrice: state => {
    return state.price
  }
}

const actions = {
  nextStep ({ commit }) {
    commit('NEXT_STEP')
  },

  precedentStep ({ commit }) {
    commit('PRECEDENT_STEP')
  },

  setStep ({ commit }, step) {
    commit('SET_STEP', step)
  },

  setPaymentIntentSecret ({ commit }, paymentIntentSecret) {
    commit('SET_PAYMENT_INTENT_SECRET', paymentIntentSecret)
  },

  setOptions ({ commit }, options) {
    commit('SET_OPTIONS', options)
  },

  setSelectedOptions ({ commit }, options) {
    commit('SET_SELECTED_OPTIONS', options)
    commit('UPDATE_PRICE')
  },

  setSelectedOption ({ commit }, { index, option }) {
    commit('SET_SELECTED_OPTION', { index, option })
    commit('UPDATE_PRICE')
  },

  addSelectedOption ({ commit }, option) {
    if (state.selectedOptions.some(o => o.id === option.id)) return
    commit('ADD_SELECTED_OPTIONS', option)
    commit('UPDATE_PRICE')
  },
  removeSelectedOption ({ commit }, option) {
    if (state.selectedOptions.some(o => o.id !== option.id)) return
    commit('REMOVE_SELECTED_OPTIONS', option)
    commit('UPDATE_PRICE')
  },
  clearSelectedOptions ({ commit }) {
    commit('CLEAR_SELECTED_OPTIONS')
    commit('UPDATE_PRICE')
  }
}

const mutations = {
  NEXT_STEP (state) {
    if (state.step >= state.steps.length) return
    state.step++
  },

  PRECEDENT_STEP (state) {
    if (state.step <= 1) return
    state.step--
  },

  SET_STEP (state, step) {
    state.step = step
  },

  SET_PAYMENT_INTENT_SECRET (state, paymentIntentSecret) {
    state.stripe.paymentIntentSecret = paymentIntentSecret
  },

  SET_OPTIONS (state, options) {
    state.options = options
  },

  SET_SELECTED_OPTIONS (state, options) {
    state.selectedOptions = options
  },

  SET_SELECTED_OPTION (state, { index, option }) {
    Vue.set(state.selectedOptions, index, option)
  },

  ADD_SELECTED_OPTIONS (state, option) {
    state.selectedOptions.push(option)
  },

  REMOVE_SELECTED_OPTIONS (state, option) {
    remove(state.selectedOptions, (o) => {
      return o.id === option.id
    })
  },

  CLEAR_SELECTED_OPTIONS (state) {
    state.selectedOptions = []
  },

  UPDATE_PRICE (state) {
    const reducer = (accumulator = 0, currentValue = 0) => accumulator + currentValue
    if (state.selectedOptions.length === 0) {
      state.price = 0
      return
    }
    state.price = state.selectedOptions.filter(o => o !== undefined).map(o => o.price).reduce(reducer, 0)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
