import Request from '../utils/Request'

let API = null
const APIAddress = new Request('https://api-adresse.data.gouv.fr', false)
const APIPostalCode = new Request('https://data.opendatasoft.com', false)

const setMainAPI = (_API) => {
  API = _API
}

export { API, APIAddress, APIPostalCode, setMainAPI }
