import Vue from 'vue'

Vue.prototype.$toastError = function ({ title = 'Une erreur est survenue', timer = 3000 } = { title, timer }) {
  return this.$swal({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
    icon: 'error',
    title
  })
}

Vue.prototype.$toastSuccess = function ({ title = 'Succès', timer = 3000 } = { title, timer }) {
  return this.$swal({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
    icon: 'success',
    title
  })
}
