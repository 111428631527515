import RouteService from './route.service'
import { AuthenticationError } from './user.service'
import { encodeForURL } from '../utils'

const OfferService = {

  async fetchConversations (userId) {
    try {
      const response = await RouteService.getUserSelfConversations(userId)
      return response.data.conversations
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.error)
    }
  },

  async fetchMessages (conversationId) {
    try {
      const response = await RouteService.getMessagesConversations(conversationId)
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.error)
    }
  },

  async fetchOffers (userId = 'self') {
    try {
      const response = await RouteService.getOffers(userId)
      return response.data.offers
    } catch (error) {
      if (userId === 'self')
        throw new AuthenticationError(error.response.status, error.response.data.error)
    }
  },

  async searchOffers (params) {
    try {
      const response = await RouteService.searchOffers(encodeForURL(params))
      return response.data
    } catch (e) {
      throw e
    }
  }
}

export default OfferService
