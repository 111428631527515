
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'CountrySelectorNavbar',
  data () {
    return {
      showDropdown: false,
    }
  },
  computed: {
    ...mapState('layout', ['currentLocale', 'currentCountry']),
    ...mapGetters({
      countries: 'layout/getCountries',
      languages: 'layout/getLanguages',
    }),
    currentCountryName () {
      return this.countries.find(country => country.isoCode === this.currentCountry)?.name
    },
    currentLanguageName () {
      return this.$i18n.localeProperties.lang
    }
  },
  methods: {
    toggleDropdown () {
      this.showDropdown = !this.showDropdown
    },
    hideDropdown () {
      this.showDropdown = false
    },
    selectLanguage (lang) {
      this.$store.commit('layout/SET_COUNTRY', lang.country.isoCode)
      this.$store.commit('layout/SET_LOCALE', { locale: lang.code })
      this.showDropdown = false
    }
  }
}
