import Vue from 'vue'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  faSearch,
  faUserCircle,
  faInfoCircle,
  faKey,
  faLink,
  faClipboardList,
  faComments,
  faSignOutAlt,
  faTrash,
  faTrashAlt,
  faArrowAltCircleRight,
  faChevronLeft,
  faChevronRight,
  faHome,
  faPhone,
  faEnvelope,
  faTags,
  faCamera,
  faRedoAlt,
  faSpinner,
  faFileInvoice,
  faCog,
  faCheck,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faHeart,
  faImage,
  faLocationArrow,
  faLanguage,
  faPhoneAlt,
  faPlusSquare,
  faImages,
  faShoppingCart,
  faCheckCircle,
  faTimesCircle,
  faTimes,
  faEllipsisH,
  faCreditCard,
  faStar,
  faEdit,
  faChevronCircleLeft,
  faCircleNotch,
  faMapMarkerAlt,
  faEllipsisV,
  faHandHoldingHeart,
  faCircle,
  faAd,
  faEuroSign,
  faClock,
  faWallet,
  faExclamationTriangle,
  faPlusCircle,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  faCompass,
  faHeart as farHeart,
  faEye,
  faArrowAltCircleRight as farArrowAltCircleRight,
  faTimesCircle as farTimesCircle
} from '@fortawesome/free-regular-svg-icons'
import {
  faGoogle,
  faFacebookF,
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faXTwitter,
  faLinkedin,
  faCcVisa,
  faCcMastercard,
  faCcDiscover,
  faCcAmex,
  faCcDinersClub,
  faCcJcb,
} from '@fortawesome/free-brands-svg-icons'

config.autoAddCss = false

library.add(
  faSearch,
  faUserCircle,
  faInfoCircle,
  faKey,
  faLink,
  faClipboardList,
  faHeart,
  farHeart,
  faComments,
  faSignOutAlt,
  faTrash,
  faTrashAlt,
  faChevronLeft,
  faChevronRight,
  faHome,
  faPhone,
  faEnvelope,
  faTags,
  faCamera,
  faRedoAlt,
  faSpinner,
  faFileInvoice,
  faCog,
  faCheck,
  faCompass,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faImage,
  faLocationArrow,
  faPhoneAlt,
  faArrowAltCircleRight,
  faEye, faPlusSquare,
  farArrowAltCircleRight,
  faImages,
  faShoppingCart,
  faCheckCircle,
  faTimes,
  faTimesCircle,
  faEllipsisH,
  faCreditCard,
  farTimesCircle,
  faStar, faEdit,
  faChevronCircleLeft,
  faCircleNotch,
  faEllipsisV,
  faMapMarkerAlt,
  faHandHoldingHeart,
  faCircle,
  faAd,
  faEuroSign,
  faLanguage,
  faClock,
  faWallet,
  faExclamationTriangle,
  faPlusCircle,
  faSignInAlt,
)

// Add socials
library.add(
  faGoogle,
  faFacebookF,
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faXTwitter,
  faLinkedin,
  )

// Add card icons
library.add(
  faCcVisa,
  faCcMastercard,
  faCcDiscover,
  faCcAmex,
  faCcDinersClub,
  faCcJcb,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
