import UserService from '@/services/user.service'
import RouteService from '@/services/route.service'
import { getCurrenciesAvailableForCountry } from '../utils'

const state = () => ({
  user: undefined,
  offersLiked: {}
})

const getters = {
  getUser: state => {
    return state.user
  },

  getOffersLiked: state => {
    return state.offersLiked
  },

  getCurrency: state => {
    return getCurrenciesAvailableForCountry(state.user.countryId)[0]
  }
}

const actions = {
  async fetchUser ({ commit }) {
    const user = await UserService.fetchUser()
    commit('setUser', user)
  },

  clearUser ({ commit }) {
    commit('removeUser')
  },

  async fetchOffersLiked ({ commit }) {
    const offersLiked = await UserService.fetchOffersIdLiked()
    commit('setOffersLiked', offersLiked)
  },

  async unLikeOffer ({ commit }, offer) {
    await RouteService.removeOfferLike(offer.id)
    commit('removeOfferLiked', offer.id)
  },

  async likeOffer ({ commit, dispatch }, offer) {
    await RouteService.addOfferLike(offer.id)
    commit('addOfferLiked', offer.id)
    dispatch('gtm/addToWishlist', offer, { root: true })
  }
}

const mutations = {
  setUser (state, user) {
    state.user = user
  },

  removeUser (state) {
    state.user = undefined
  },

  setOffersLiked (state, offers) {
    state.offersLiked = offers
  },

  addOfferLiked (state, id) {
    state.offersLiked.push(id)
  },

  removeOfferLiked (state, id) {
    state.offersLiked = state.offersLiked.filter(i => i !== id)
  },

  setOffersAddress (state, { offersAddress, offersAddressId }) {
    state.user.offersAddress = offersAddress
    state.user.offersAddressId = offersAddressId
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
