import { mapItemFB, mapItemGA } from '@/utils/googleTagManager'

const state = () => {
}
const getters = {}
const mutations = {}

//--- Event prefixed by c_ are custom events
const actions = {

  setUserProperties ({ rootState }, properties) {
    this.$gtm.push({ userId: rootState.user.id, ...properties })
  },

  signUp (_, method) {
    this.$gtm.push({ event: 'ga.sign_up', method })
    this.$gtm.push({
      event: 'fb.CompleteRegistration',
      currency: 'EUR',
      value: 1
    })
  },

  login (_, method) {
    this.$gtm.push({ event: 'ga.login', method })
  },

  // TODO Determine who are the leads
  lead (_) {
    this.$gtm.push({
      event: 'ga.generate_lead',
      currency: 'EUR',
      // value: 99.99,
      // transaction_id: 'T_12345'
    })
    this.$gtm.push({
      event: 'fb.Lead',
      currency: 'EUR',
      // content_category,
      // content_name,
      // value
    })
  },

  search (_, term) {
    this.$gtm.push({
      event: 'ga.search',
      search_term: term
    })
    this.$gtm.push({
      event: 'fb.Search',
      search_string: term,
      currency: 'EUR'
    })
  },

  clickSponsoredItem (_, { item, list, listId }) {
    // this.$gtm.push({
    //   event: 'ga.select_sponsored_item',
    //   currency: 'EUR',
    //   items: [mapItemGA(item)], // TODO Validate
    //   value: Number(item.price)
    // })
  },

   impressionSponsoredItem (_, { item, list, listId }) {
    // this.$gtm.push({
    //   event: 'ga.impression_sponsored_item',
    //   currency: 'EUR',
    //   items: [mapItemGA(item)], // TODO Validate
    //   value: Number(item.price)
    // })
  },

  clickItem (_, { item, list, listId }) {
    this.$gtm.push({
      event: 'ga.select_item',
      currency: 'EUR',
      items: [mapItemGA(item)],
      item_list_name: list,
      item_list_id: listId,
    })
  },

  viewItem (_, item) {
    this.$gtm.push({
      event: 'ga.view_item',
      currency: 'EUR',
      items: [mapItemGA(item)],
      value: Number(item.price)
    })
  },

  viewItemList (_, { listId, list, item, index }) {
    this.$gtm.push({
      event: 'ga.view_item_list',
      items: [{
        ...mapItemGA(item),
        item_list_id: listId,
        item_list_name: list,
        index,
      }],
    })
  },

  addToWishlist (_, item) {
    this.$gtm.push({
      event: 'ga.add_to_wishlist',
      currency: 'EUR',
      items: [mapItemGA(item)],
      value: item.price
    })
    this.$gtm.push({
      event: 'fb.AddToWishlist',
      currency: 'EUR',
      ...mapItemFB(item)
    })
  },

  subscribeToNewsletter (_) {
    this.$gtm.push({ event: 'ga.c_subscribe_to_newsletter' })
    this.$gtm.push({ event: 'fb.c_SubscribeToNewsletter' })
  },

  contact (_) {
    this.$gtm.push({ event: 'ga.c_contact' })
    this.$gtm.push({ event: 'fb.Contact' })
  },

  viewAd (_, ad) {
    this.$gtm.push({
      event: 'ga.c_view_ad',
      id: ad.id
    })
    this.$gtm.push({
      event: 'ga.c_view_ad_type',
      type: ad.type
    })
  },

  clickAd (_, ad) {
    this.$gtm.push({
      event: 'ga.c_click_ad',
      id: ad.id
    })
    this.$gtm.push({
      event: 'ga.c_view_ad_type',
      type: ad.type
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
