import cookie from 'cookie'
import routes from '@/services/route.service'
import jsCookie from 'js-cookie'

const state = () => ({
  loading: true,
  cookiesConsent: false
})

const getters = {}

const actions = {
  async nuxtServerInit ({ commit, dispatch }, { req }) {
    await Promise.all([
      dispatch('layout/setupLocale'),
      dispatch('layout/fetchCategories'),
      dispatch('layout/fetchBrands'),
    ])

    if (!req.headers.cookie) return
    const cookies = cookie.parse(req.headers.cookie)

    if (cookies[this.$config.cookies.consent]) {
      commit('SET_COOKIE_CONSENT', !!cookies[this.$config.cookies.consent])
    }

    if (cookies[this.$config.cookies.auth]) {
      try {
        const { data } = await routes.checkLogged(cookies[this.$config.cookies.auth])
        await dispatch('auth/loginFromCookie')
        await dispatch('gtm/setUserProperties', { userId: data.userId })
        this.$bugsnag.setUser(data.userId)
      } catch (e) {
        // Nothing
      }
    }
  },
  acceptCookies ({ commit }) {
    jsCookie.set(this.$config.cookies.consent, true, { expires: 365 })
    commit('SET_COOKIE_CONSENT', true)
  },
  rejectCookies ({ commit }) {
    jsCookie.set(this.$config.cookies.consent, false)
    commit('SET_COOKIE_CONSENT', false)
  }
}

const mutations = {
  SET_COOKIE_CONSENT (state, cookie) {
    state.cookiesConsent = cookie
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
