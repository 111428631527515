export const BraveAlert = () => import('../../components/alert/BraveAlert.vue' /* webpackChunkName: "components/brave-alert" */).then(c => wrapFunctional(c.default || c))
export const PromotionalMessage = () => import('../../components/alert/PromotionalMessage.vue' /* webpackChunkName: "components/promotional-message" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BannerView = () => import('../../components/banner/BannerView.vue' /* webpackChunkName: "components/banner-view" */).then(c => wrapFunctional(c.default || c))
export const ContestPopup = () => import('../../components/contest/ContestPopup.vue' /* webpackChunkName: "components/contest-popup" */).then(c => wrapFunctional(c.default || c))
export const Error404 = () => import('../../components/error/error404.vue' /* webpackChunkName: "components/error404" */).then(c => wrapFunctional(c.default || c))
export const Error500 = () => import('../../components/error/error500.vue' /* webpackChunkName: "components/error500" */).then(c => wrapFunctional(c.default || c))
export const HomeBrands = () => import('../../components/home/HomeBrands.vue' /* webpackChunkName: "components/home-brands" */).then(c => wrapFunctional(c.default || c))
export const HomeMedias = () => import('../../components/home/HomeMedias.vue' /* webpackChunkName: "components/home-medias" */).then(c => wrapFunctional(c.default || c))
export const HomeOffers = () => import('../../components/home/HomeOffers.vue' /* webpackChunkName: "components/home-offers" */).then(c => wrapFunctional(c.default || c))
export const HomePartners = () => import('../../components/home/HomePartners.vue' /* webpackChunkName: "components/home-partners" */).then(c => wrapFunctional(c.default || c))
export const AlertError = () => import('../../components/includes/AlertError.vue' /* webpackChunkName: "components/alert-error" */).then(c => wrapFunctional(c.default || c))
export const AlertInfo = () => import('../../components/includes/AlertInfo.vue' /* webpackChunkName: "components/alert-info" */).then(c => wrapFunctional(c.default || c))
export const AlertSuccess = () => import('../../components/includes/AlertSuccess.vue' /* webpackChunkName: "components/alert-success" */).then(c => wrapFunctional(c.default || c))
export const Alerts = () => import('../../components/includes/Alerts.vue' /* webpackChunkName: "components/alerts" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/includes/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const ButtonsLogin = () => import('../../components/includes/ButtonsLogin.vue' /* webpackChunkName: "components/buttons-login" */).then(c => wrapFunctional(c.default || c))
export const Captcha = () => import('../../components/includes/Captcha.vue' /* webpackChunkName: "components/captcha" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/includes/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const InputBrand = () => import('../../components/input/InputBrand.vue' /* webpackChunkName: "components/input-brand" */).then(c => wrapFunctional(c.default || c))
export const InputIcon = () => import('../../components/input/InputIcon.vue' /* webpackChunkName: "components/input-icon" */).then(c => wrapFunctional(c.default || c))
export const InputPostalCodeAutocomplete = () => import('../../components/input/InputPostalCodeAutocomplete.vue' /* webpackChunkName: "components/input-postal-code-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const SelectBrand = () => import('../../components/input/SelectBrand.vue' /* webpackChunkName: "components/select-brand" */).then(c => wrapFunctional(c.default || c))
export const FiltersInputPriceRange = () => import('../../components/input/filters/InputPriceRange.vue' /* webpackChunkName: "components/filters-input-price-range" */).then(c => wrapFunctional(c.default || c))
export const NoResult = () => import('../../components/offer/NoResult.vue' /* webpackChunkName: "components/no-result" */).then(c => wrapFunctional(c.default || c))
export const OfferCard = () => import('../../components/offer/OfferCard.vue' /* webpackChunkName: "components/offer-card" */).then(c => wrapFunctional(c.default || c))
export const OfferSponsoredCard = () => import('../../components/offer/OfferSponsoredCard.vue' /* webpackChunkName: "components/offer-sponsored-card" */).then(c => wrapFunctional(c.default || c))
export const PostOfferCard = () => import('../../components/offer/PostOfferCard.vue' /* webpackChunkName: "components/post-offer-card" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/offer/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchBar2 = () => import('../../components/offer/SearchBar2.vue' /* webpackChunkName: "components/search-bar2" */).then(c => wrapFunctional(c.default || c))
export const SearchBar3 = () => import('../../components/offer/SearchBar3.vue' /* webpackChunkName: "components/search-bar3" */).then(c => wrapFunctional(c.default || c))
export const SearchBarModal = () => import('../../components/offer/SearchBarModal.vue' /* webpackChunkName: "components/search-bar-modal" */).then(c => wrapFunctional(c.default || c))
export const SearchBlock = () => import('../../components/offer/SearchBlock.vue' /* webpackChunkName: "components/search-block" */).then(c => wrapFunctional(c.default || c))
export const SearchMixin = () => import('../../components/offer/SearchMixin.vue' /* webpackChunkName: "components/search-mixin" */).then(c => wrapFunctional(c.default || c))
export const PostOfferCheckout = () => import('../../components/postoffer/PostOfferCheckout.vue' /* webpackChunkName: "components/post-offer-checkout" */).then(c => wrapFunctional(c.default || c))
export const PostOfferInfo = () => import('../../components/postoffer/PostOfferInfo.vue' /* webpackChunkName: "components/post-offer-info" */).then(c => wrapFunctional(c.default || c))
export const PostOfferLocation = () => import('../../components/postoffer/PostOfferLocation.vue' /* webpackChunkName: "components/post-offer-location" */).then(c => wrapFunctional(c.default || c))
export const PostOfferOptions = () => import('../../components/postoffer/PostOfferOptions.vue' /* webpackChunkName: "components/post-offer-options" */).then(c => wrapFunctional(c.default || c))
export const PostOfferReview = () => import('../../components/postoffer/PostOfferReview.vue' /* webpackChunkName: "components/post-offer-review" */).then(c => wrapFunctional(c.default || c))
export const FilepickerFile = () => import('../../components/postoffer/filepicker/File.vue' /* webpackChunkName: "components/filepicker-file" */).then(c => wrapFunctional(c.default || c))
export const FilepickerFileAdd = () => import('../../components/postoffer/filepicker/FileAdd.vue' /* webpackChunkName: "components/filepicker-file-add" */).then(c => wrapFunctional(c.default || c))
export const FilepickerFileMore = () => import('../../components/postoffer/filepicker/FileMore.vue' /* webpackChunkName: "components/filepicker-file-more" */).then(c => wrapFunctional(c.default || c))
export const FilepickerFilePicker = () => import('../../components/postoffer/filepicker/FilePicker.vue' /* webpackChunkName: "components/filepicker-file-picker" */).then(c => wrapFunctional(c.default || c))
export const FilepickerUploadingLoader = () => import('../../components/postoffer/filepicker/UploadingLoader.vue' /* webpackChunkName: "components/filepicker-uploading-loader" */).then(c => wrapFunctional(c.default || c))
export const StepperStep = () => import('../../components/postoffer/stepper/Step.vue' /* webpackChunkName: "components/stepper-step" */).then(c => wrapFunctional(c.default || c))
export const Stepper = () => import('../../components/postoffer/stepper/Stepper.vue' /* webpackChunkName: "components/stepper" */).then(c => wrapFunctional(c.default || c))
export const AccountHeader = () => import('../../components/user/account/AccountHeader.vue' /* webpackChunkName: "components/account-header" */).then(c => wrapFunctional(c.default || c))
export const AccountFavoritesOfferRowLike = () => import('../../components/user/account/favorites/OfferRowLike.vue' /* webpackChunkName: "components/account-favorites-offer-row-like" */).then(c => wrapFunctional(c.default || c))
export const AccountMessagesConversationContent = () => import('../../components/user/account/messages/ConversationContent.vue' /* webpackChunkName: "components/account-messages-conversation-content" */).then(c => wrapFunctional(c.default || c))
export const AccountMessagesConversationRow = () => import('../../components/user/account/messages/ConversationRow.vue' /* webpackChunkName: "components/account-messages-conversation-row" */).then(c => wrapFunctional(c.default || c))
export const AccountMyoffersOfferRow = () => import('../../components/user/account/myoffers/OfferRow.vue' /* webpackChunkName: "components/account-myoffers-offer-row" */).then(c => wrapFunctional(c.default || c))
export const AccountTransactionsTransactionRow = () => import('../../components/user/account/transactions/TransactionRow.vue' /* webpackChunkName: "components/account-transactions-transaction-row" */).then(c => wrapFunctional(c.default || c))
export const AccountTransactionsTransactionStatus = () => import('../../components/user/account/transactions/TransactionStatus.vue' /* webpackChunkName: "components/account-transactions-transaction-status" */).then(c => wrapFunctional(c.default || c))
export const AccountTransactionsTransactionTable = () => import('../../components/user/account/transactions/TransactionTable.vue' /* webpackChunkName: "components/account-transactions-transaction-table" */).then(c => wrapFunctional(c.default || c))
export const CookieBanner = () => import('../../components/CookieBanner.vue' /* webpackChunkName: "components/cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const CountrySelector = () => import('../../components/CountrySelector.vue' /* webpackChunkName: "components/country-selector" */).then(c => wrapFunctional(c.default || c))
export const CountrySelectorNavbar = () => import('../../components/CountrySelectorNavbar.vue' /* webpackChunkName: "components/country-selector-navbar" */).then(c => wrapFunctional(c.default || c))
export const GoogleSearchCity = () => import('../../components/GoogleSearchCity.vue' /* webpackChunkName: "components/google-search-city" */).then(c => wrapFunctional(c.default || c))
export const HeaderTitle = () => import('../../components/HeaderTitle.vue' /* webpackChunkName: "components/header-title" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const LocationMap = () => import('../../components/LocationMap.vue' /* webpackChunkName: "components/location-map" */).then(c => wrapFunctional(c.default || c))
export const NavigationLink = () => import('../../components/NavigationLink.vue' /* webpackChunkName: "components/navigation-link" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
