import { API } from '@/services/api.service'

const state = () => ({
  notifications: [],
  notificationsMessageCount: 0,
  notificationsOfferCount: 0
})

const getters = {
  getNotifications: state => {
    return state.notifications
  },
  getNotificationsMessageCount: state => {
    return state.notificationsMessageCount
  },
  getNotificationsOfferCount: state => {
    return state.notificationsOfferCount
  },

  getMessagesNotifications: state => {
    return state.notifications.filter(n => n.type && n.type.name.startsWith('MESSAGE_'))
  },
  getOffersNotifications: state => {
    return state.notifications.filter(n => n.type && n.type.name.startsWith('OFFER_'))
  }
}

const actions = {
  async fetchNotifications ({ commit }) {
    try {
      const response = await API.get('/notifications')
      commit('SET_NOTIFICATIONS', response.data.notifications)
      commit('UPDATE_COUNT')
    } catch (e) {
      console.error(e)
    }
  },

  // addNotification ({ commit }, { type, detail, data }) {
  //   switch (type) {
  //     case 'MESSAGE_RECEIVE_F' || 'MESSAGE_RECEIVE':
  //       const { conversationId, msg } = data
  //       commit('NOTIFICATION_NEW_MESSAGE', { conversationId, msg })
  //       break
  //     case 'OFFER_ACCEPTED':
  //       const { offerId } = data
  //       commit('') // TODO
  //       break
  //     case 'OFFER_REFUSED':
  //       const { offerId } = data
  //       commit('') // TODO
  //       break
  //   }
  // },

  async readAllOffers ({ commit }) {
    await API.delete(`/notifications/offers`)
    commit('READ_OFFERS')
    commit('UPDATE_COUNT')
  },

  async readOffer ({ commit }, offerId) {
    await API.delete(`/offers/${offerId}/notifications`)
    commit('READ_OFFER', offerId)
    commit('UPDATE_COUNT')
  },

  async readConversation ({ commit }, conversationId) {
    await API.delete(`/conversations/${conversationId}/notifications`)
    commit('READ_CONVERSATION', conversationId)
    commit('UPDATE_COUNT')
  },

  // async readNotification ({ commit }, id) {
  //   await API.delete(`/notifications/${id}`)
  //   commit('READ_NOTIFICATION', id)
  //   commit('UPDATE_COUNT')
  // },

  listenNewNotifications ({ commit }, socket) {
    socket.on('NOTIFICATION_NEW', data => {
      commit('ADD_NOTIFICATION', data)
      commit('UPDATE_COUNT')
    })
  },

  listenReadNotifications ({ commit }, socket) {
    socket.on('NOTIFICATION_READ', ids => {
      commit('READ_NOTIFICATION', ids)
      commit('UPDATE_COUNT')
    })
  }
}

const mutations = {
  ADD_NOTIFICATION (state, { notification }) {
    state.notifications.push(notification)
  },
  SET_NOTIFICATIONS (state, notifications) {
    state.notifications = notifications
  },
  READ_NOTIFICATION (state, notificationsId) {
    state.notifications = state.notifications.filter(n => !notificationsId.includes(n.id))
  },
  READ_OFFERS (state) {
    state.notifications = state.notifications.filter(n => !n.type.name.startsWith('OFFER_'))
  },
  READ_OFFER (state, offerId) {
    state.notifications = state.notifications.filter(n => n.type.name.startsWith('OFFER_') && n.entityId !== offerId)
  },
  READ_CONVERSATION (state, conversationId) {
    state.notifications = state.notifications.filter(n => n.type.name.startsWith('MESSAGE_') && n.entityId !== conversationId)
  },
  UPDATE_COUNT (state) {
    state.notificationsMessageCount = state.notifications ? state.notifications.filter(n => n && n.type && n.type.name.startsWith('MESSAGE_')).length : 0
    state.notificationsOfferCount = state.notifications ? state.notifications.filter(n => n && n.type && n.type.name.startsWith('OFFER_')).length : 0
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
