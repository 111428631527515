import { API } from './api.service'
import { removeEmptyString } from '../utils'

export default {
  login (data) {
    return API.post('/auth/login', data)
  },
  logout () {
    return API.post('/auth/logout')
  },
  checkLogged (token) {
    return API.get('/auth/check', { headers: { 'x-access-token': token } })
  },
  createUser (params) {
    return API.put('/users', params)
  },
  updateUser (id, params) {
    return API.put(`/users/${id}`, params)
  },
  updateUserSelf (params) {
    removeEmptyString(params)
    return API.put('/users/self', params)
  },
  updateUserSocialsSelf (params) {
    return API.put('/users/self/socials', params)
  },
  changePassSelf (params) {
    return API.post('/users/self/password', params)
  },
  getUser (id) {
    return API.get(`/users/${id}`)
  },
  getUserSelf () {
    return API.get('/users/self')
  },
  getOffersIdLiked () {
    return API.get('/users/self/offers/liked')
  },
  getOffersLiked () {
    return API.get('/users/self/offers/liked?withOffer=true')
  },
  getUserProfile (username) {
    return API.get(`/profiles/${username}`)
  },
  deleteUser (id) {
    return API.delete(`/users/${id}`)
  },
  deleteUserSelf () {
    return API.delete('/users/self')
  },
  sendForgotPassword (body) {
    return API.post('/users/forgot-password', body)
  },
  changePass (body) {
    return API.post(`/users/change-password`, body)
  },
  getOffer (id) {
    return API.get(`/offers/${id}`)
  },
  postOffer (data) {
    return API.post('/offers/', data)
  },
  addOfferImgs (id, data) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }
    return API.post(`/offers/${id}/img`, data, config)
  },
  updateOffer (id, data) {
    return API.put(`/offers/${id}`, data)
  },
  searchOffers (param) {
    return API.get(`/offers?${param}`)
  },
  getUserSelfConversations () {
    return API.get(`/users/self/conversations`)
  },
  getBrands (from = 0, limit = 20) {
    return API.get(`/brands?from=${from}&limit=${limit}`)
  },
  getBrand (id) {
    return API.get(`/brands/${id}`)
  },
  getCategories (from = 0, limit = 100) {
    return API.get(`/categories?from=${from}&limit=${limit}`)
  },
  getCategory (id) {
    return API.get(`/categories/${id}`)
  },
  getOffers (userId) {
    return API.get(`/users/${userId}/offers`)
  },
  getOfferWithSlug (slug) {
    return API.get(`/offers/slug/${slug}`)
  },
  getSimilarOffers (offerId) {
    return API.get(`/offers/${offerId}/similar`)
  },
  createConversation (offerId, msg) {
    return API.post(`/conversations`, { offerId, msg })
  },
  getMessagesConversations (conversationId) {
    return API.get(`/conversations/${conversationId}/messages`)
  },
  postMessage (conversationId, msg) {
    return API.post(`/conversations/${conversationId}/messages`, { msg })
  },
  addOfferLike (offerId) {
    return API.post(`/users/self/offers/${offerId}/liked`)
  },
  removeOfferLike (offerId) {
    return API.delete(`/users/self/offers/${offerId}/liked`)
  },
  getBanners (type) {
    return API.get(`/sda?type=${type}`)
  }
}
