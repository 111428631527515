import { insertAndShift } from '@/utils'

const state = () => ({
  files: []
})

const getters = {
  getFiles: state => {
    return state.files
  },
  getNbrFile: state => {
    return state.files.length
  }
}

const actions = {
  setFiles ({ commit }, files) {
    commit('SET_FILES', files)
  },

  addFile ({ commit }, file) {
    commit('ADD_FILE', file)
  },

  removeFile ({ commit }, id) {
    commit('REMOVE_FILE', id)
  },

  moveLeft ({ commit }, index) {
    commit('MOVE_LEFT', index)
  },

  moveRight ({ commit }, index) {
    commit('MOVE_RIGHT', index)
  }
}

const mutations = {
  SET_FILES (state, files) {
    state.files = files
  },

  ADD_FILE (state, file) {
    state.files.push({ order: state.files.length, ...file })
  },

  REMOVE_FILE (state, id) {
    state.files = state.files.filter(file => file.id !== id)
  },

  MOVE_LEFT (state, oldIndex) {
    if (oldIndex - 1 < 0) return
    insertAndShift(state.files, oldIndex, oldIndex - 1)
  },

  MOVE_RIGHT (state, oldIndex) {
    if (oldIndex + 2 > state.files.length) return
    insertAndShift(state.files, oldIndex, oldIndex + 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
