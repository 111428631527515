import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import updateLocale from 'dayjs/plugin/updateLocale'
import calendar from 'dayjs/plugin/calendar'
import 'dayjs/locale/fr'
import 'dayjs/locale/fr-ch'
import 'dayjs/locale/fr-ca'
import 'dayjs/locale/en-ca'
import 'dayjs/locale/it'
import 'dayjs/locale/it-ch'
import 'dayjs/locale/de'
import 'dayjs/locale/de-ch'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(updateLocale)
dayjs.extend(calendar)

dayjs.updateLocale('fr', {
  s: 'un instant',
  calendar: {
    lastDay: '[Hier à] LT',
    sameDay: `[Aujourd'hui à] LT`,
    nextDay: '[Demain à] LT',
    lastWeek: 'dddd [dernier à] LT',
    nextWeek: 'dddd [à] LT',
    sameElse: 'D MMM à hh:mm'
  }
})

export default (ctx, inject) => {
  const lang = ctx.i18n.localeProperties.lang
  dayjs.locale(lang)

  ctx.$dayjs = dayjs
  inject('dayjs', dayjs)
}
