import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=5e96b33e&scoped=true"
import script from "./AppFooter.vue?vue&type=script&lang=js"
export * from "./AppFooter.vue?vue&type=script&lang=js"
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=5e96b33e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e96b33e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CountrySelector: require('/app/components/CountrySelector.vue').default})
