import { setMainAPI } from '@/services/api.service'

export default function ({ $axios, i18n, redirect }) {
  setMainAPI($axios)

  const isoCode = i18n.localeProperties.iso
  $axios.setHeader('Accept-Language', isoCode)

  $axios.onRequest(config => {
    config.withCredentials = true
  })

  $axios.onError(error => {
    // const code = parseInt(error.response && error.response.status)
    // if (code === 400) {
    //   redirect('/400')
    // }
  })
}
