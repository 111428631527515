
import Cookie from 'js-cookie'
import { isBot } from '../../utils'

const COOKIE_NAME = 'promotional_message'
const DISPLAY_START = '2023-03-14 00:00:00'
const DISPLAY_UNTIL = '2023-03-15 01:00:00'

export default {
  name: 'PromotionalMessage',
  data() {
    return {
      display: false
    }
  },
  computed: {
    displayPopup () {
      return this.display
        && this.$dayjs().isAfter(this.$dayjs(DISPLAY_START))
        && this.$dayjs().isBefore(this.$dayjs(DISPLAY_UNTIL))
        && !isBot(navigator.userAgent)
    }
  },
  created () {
    if (process.server) return
    this.display = !Cookie.get(COOKIE_NAME)
  },
  methods: {
    hidePopup () {
      this.display = false
      Cookie.set(COOKIE_NAME, 'false', { path: '/', expires: 1 / 12 }) // 1 hour
      this.$emit('hide')
    }
  }
}
