import config from '../config'
import Currency from '../enum/currency'

export function encodeForURL (params) {
  let str = ''
  for (const key in params) {
    if (str !== '') {
      str += '&'
    }
    str += key + '=' + encodeURIComponent(params[key])
  }
  return str
}

export function isNullOrUndef (value) {
  return value === null || value === undefined
}

export function insertAndShift (arr, from, to) {
  const cutOut = arr.splice(from, 1)[0]
  arr.splice(to, 0, cutOut)
}

export function removeEmptyString (obj) {
  for (var key in obj) {
    if (obj[key] === '')
      obj[key] = null
  }
}

export function noop () {
}

export function roundPrice (price) {
  return Math.round((parseFloat(price) + Number.EPSILON) * 100) / 100
}

export async function injectScript (url) {
  return new Promise((resolve) => {
    let documentTag = document, tag = 'script',
      object = documentTag.createElement(tag),
      scriptTag = documentTag.getElementsByTagName(tag)[0]
    object.src = url
    object.addEventListener('load', function (e) {
      resolve(e)
    }, false)
    scriptTag.parentNode.insertBefore(object, scriptTag)
  })
}

export async function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export function getRootPath (lang) {
  if (process.env.MODE === 'production') {
    return `${config.baseUrl}${lang !== 'fr-fr' ? `/${lang}/` : ''}`
  }
  if (process.env.MODE === 'staging') {
    return `${config.baseUrl}${lang !== 'fr-fr' ? `/${lang}/` : ''}`
  }
  return `${config.baseUrl}${lang !== 'fr-fr' ? `/${lang}/` : ''}`
}

export function getCurrenciesAvailableForCountry (countryId) {
  return Object.values(Currency).filter((c) => c.countryIds.includes(countryId))
}

export function isBot (userAgent) {
  const botsRegex = /bot|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|yandex/i;
  return botsRegex.test(userAgent.toLowerCase())
}
