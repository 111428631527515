import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import OfferService from '@/services/offer.service'

const defaultFilters = {
  arms: [],
  page: 1,
  distance: 50,
  // limit: 28,
  // brand: null,
  brands: null,
  sort: 'publishedAt',
  order: 'desc',
  minPrice: undefined,
  maxPrice: undefined,
}

const state = () => ({
  searchBarOpened: false,
  filters: defaultFilters,
  maxResults: 0,
  maxPages: 1,
  offers: [],
  offersSponsored: [],
  loading: true
})

const getters = {
  searchBarIsOpened: state => {
    return state.searchBarOpened
  },

  getCurrentPage: state => {
    return state.filters.page
  },

  getMaxPages: state => {
    return state.maxPages
  },

  getMaxResults: state => {
    return state.maxResults
  },

  getFilters: state => {
    return state.filters
  },

  getArms: state => {
    return state.filters.arms ?? {}
  },

  getOffers: state => {
    return state.offers
  },

  getSponsoredOffers: state => {
    return state.offersSponsored
  },

  isLoading: state => {
    return state.loading
  }
}

const actions = {
  showSearchBar ({ commit }) {
    commit('SET_SEARCH_BAR', true)
  },

  hideSearchBar ({ commit }) {
    commit('SET_SEARCH_BAR', false)
  },

  toggleSearchBar ({ commit }) {
    commit('TOGGLE_SEARCH_BAR')
  },

  setFilters ({ commit }, filters) {
    commit('SET_FILTERS', filters)
  },

  resetFilters ({ commit }) {
    commit('SET_FILTERS', defaultFilters)
  },

  mergeFilters ({ commit }, filters) {
    commit('MERGE_FILTERS', filters)
  },

  updateArms ({ commit }, arms) {
    commit('MERGE_ARMS', arms)
  },

  setPage ({ commit }, page) {
    commit('SET_PAGE', page)
  },

  async doSearch ({ commit, state }) {
    commit('SET_LOADING', true)
    try {
      const filters = { ...state.filters }
      if (filters.arms) {
        filters.arms = Object.keys(filters.arms).filter(a => filters.arms[a])
      }
      for (const filter in filters) {
        if (filters[filter] === undefined || filters[filter] === null || filters[filter] === '' || filters[filter].length === 0) {
          delete filters[filter]
        }
      }
      delete filters.postalCode
      delete filters.city
      if (filters.ltype === 'all' || filters.ltype === 'near_me') {
        filters.allc = true
      }
      delete filters.ltype

      const { offers, offersSponsored, maxResults, maxPages } = await OfferService.searchOffers(filters)
      commit('SET_OFFERS', offers)
      // commit('SET_OFFERS_SPONSORED', offersSponsored)
      commit('SET_MAX_RESULTS', maxResults)
      commit('SET_MAX_PAGES', maxPages)
      commit('SET_LOADING', false)
    } catch (e) {
      console.error('Error', e)
      commit('SET_LOADING', false)
    }
  }
}

const mutations = {
  SET_SEARCH_BAR (state, opened) {
    state.searchBarOpened = opened
  },

  TOGGLE_SEARCH_BAR (state) {
    state.searchBarOpened = !state.searchBarOpened
  },

  SET_FILTERS (state, filters) {
    filters = omitBy(filters, isNil)
    state.filters = filters
  },

  MERGE_FILTERS (state, filters) {
    state.filters = { ...state.filters, ...filters }
  },

  MERGE_ARMS (state, arms) {
    state.filters.arms = { ...state.filters.arms, ...arms }
  },

  TOGGLE_EPEE (state) {
    if (state.filters.arms.includes('epee')) {
      state.filters.arms = state.filters.arms.filter(e => e !== 'epee')
    } else {
      state.filters.arms.push('epee')
    }
  },

  TOGGLE_FOIL (state) {
    if (state.filters.arms.includes('foil')) {
      state.filters.arms = state.filters.arms.filter(e => e !== 'foil')
    } else {
      state.filters.arms.push('foil')
    }
  },

  TOGGLE_SABER (state) {
    if (state.filters.arms.includes('saber')) {
      state.filters.arms = state.filters.arms.filter(e => e !== 'saber')
    } else {
      state.filters.arms.push('saber')
    }
  },

  SET_PAGE (state, page) {
    state.filters.page = page
  },

  SET_MAX_RESULTS (state, maxResults) {
    state.maxResults = maxResults
  },

  SET_MAX_PAGES (state, maxPages) {
    state.maxPages = maxPages
  },

  SET_OFFERS (state, offers) {
    state.offers = offers
  },

  SET_OFFERS_SPONSORED (state, offersSponsored) {
    state.offersSponsored = offersSponsored
  },

  SET_LOADING (state, loading) {
    state.loading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
