
import Cookie from 'js-cookie'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { DismissReason } from 'sweetalert2'
import { API } from '@/services/api.service'
import BraveAlert from '@/components/alert/BraveAlert'
import CookieBanner from '@/components/CookieBanner'
import { getRootPath } from '../utils'

export default {
  name: 'DefaultLayout',
  middleware: 'maintenance',
  components: { BraveAlert, CookieBanner },
  head () {
    const i18nSeo = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      title: 'Escrime-Occasion.com',
      htmlAttrs: {
        ...i18nSeo.htmlAttrs
      },
      meta: [
        { hid: 'og:url', property: 'og:url', content: this.$config.baseUrl + this.$route.path },
        ...i18nSeo.meta,
      ],
      link: [
        ...i18nSeo.link
      ],
    }
  },

  data () {
    return {
      loading: true,
      header_fixed: false,
      popup: false,
      isBrave: false,
      showPromotionalMessage: true,
    }
  },

  jsonld () {
    return [{
      '@context': 'https://schema.org',
      '@type': 'Organization',
      'name': 'Escrime-Occasion',
      'alternateName': 'Escrime-Occasion.com',
      'url': getRootPath(this.$i18n.locale),
      'logo': this.$config.baseUrl + '/assets/img/logo.svg',
      'sameAs': [
        this.$config.socials.facebook,
        this.$config.socials.twitter,
        this.$config.socials.instagram,
        this.$config.socials.linkedin
      ]
    }]
  },

  async fetch () {
    const promises = [this.fetchCountries(), this.fetchLanguages()]
    if (this.isAuthenticated) {
      promises.push(this.fetchUser())
    }
    await Promise.all(promises)
  },

  async created () {
    this.popup = !Cookie.get('hidePopup')
    // this.$nextTick(() => {
    //   setTimeout(() => (this.loading = false), 1000)
    // })

    // TODO NuxtJs uncomment
    // var self = this
    // document.addEventListener('keyup', function (event) {
    //   if (event.defaultPrevented) return
    //
    //   const key = event.key || event.keyCode
    //
    //   if (key === 'Escape' || key === 'Esc' || key === 27) {
    //     self.hideSearchBar()
    //   }
    // })

    Vue.prototype.$isMobile = false

    if (process.client) {
      window.addEventListener('scroll', this.handleScroll)
      this.handleResize()
      window.addEventListener('resize', this.handleResize)
      if (this.isAuthenticated) {
        await this.fetchOffersLiked()
        await this.subscribeToNotifications()
      }
    }
  },

  mounted () {
    this.checkUseBrave()
    this.initOneSignal()
    if (!navigator.webdriver && !Cookie.get('ask_change_country')) {
      setTimeout(this.checkCountry, 1000)
    }
  },

  beforeDestroy () {
    if (process.client) {
      window.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('resize', this.handleResize)
    }
  },

  computed: {
    ...mapState('layout', ['currentCountry']),
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'user/getUser',
      searchBarIsOpened: 'search/searchBarIsOpened'
    }),
  },
  // displayPromotionalMessage () {
  //   return this.showPromotionalMessage
  // },

  methods: {
    ...mapActions({
      hideSearchBar: 'search/hideSearchBar',
      fetchUser: 'user/fetchUser',
      fetchOffersLiked: 'user/fetchOffersLiked',
      fetchCountries: 'layout/fetchCountries',
      fetchLanguages: 'layout/fetchLanguages',
      fetchBrands: 'layout/fetchBrands',
      fetchNotifications: 'notifications/fetchNotifications',
      listenNewNotifications: 'notifications/listenNewNotifications',
      listenReadNotifications: 'notifications/listenReadNotifications'
    }),

    async subscribeToNotifications () {
      if (!this.isAuthenticated) return
      await this.fetchNotifications()
      await Promise.all([this.listenNewNotifications(this.$socket), this.listenReadNotifications(this.$socket)])
    },

    async initOneSignal () {
      if (!process.client || !this.$OneSignal || !this.$OneSignal.VERSION || !this.isAuthenticated || !this.user || this.isBrave) return

      this.$OneSignal?.setExternalUserId(this.user.id)
      this.$OneSignal?.getUserId(onesignalDeviceId => {
        if (!onesignalDeviceId) return;
        API.post('/users/onesignal', { onesignalDeviceId })
      })
      // this.$OneSignal.on('subscriptionChange', function (isSubscribed) {
      //   if (isSubscribed) {
      //     // The user is subscribed
      //     //   Either the user subscribed for the first time
      //     //   Or the user was subscribed -> unsubscribed -> subscribed
      //     window.OneSignal.getUserId(function (onesignalDeviceId) {
      //       API.post('/users/onesignal', { onesignalDeviceId })
      //     })
      //   }
      // })
    },

    handleScroll () {
      this.header_fixed = window.scrollY > 80
    },

    handleResize () {
      Vue.prototype.$isMobile = innerWidth <= 768
      Vue.prototype.$isTablet = innerWidth > 769 && innerWidth <= 992
    },

    hidePopup () {
      this.popup = false
      Cookie.set('hidePopup', 'true', { path: '/', expires: 1 / 12 })
    },

    checkUseBrave () {
      if (!process.client) return
      Vue.prototype.$isBrave = !!window.navigator.brave
      this.isBrave = Vue.prototype.$isBrave
      if (this.isBrave) {
        // TODO Send to google analytics use_brave
      }
    },

    async checkCountry () {
      const response = await fetch('https://ip2c.org/s')
      const data = await response.text()
      if (data) {
        const country = data.split(';')[1]
        if (country !== this.currentCountry) {
          if (country === 'FR') {
            const result = await this.$swal({
              title: 'Vous semblez venir de France ?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Aller sur le site 🇫🇷',
              cancelButtonText: 'Non merci',
            })
            if (result.value) {
              this.$store.commit('layout/SET_COUNTRY', country)
              this.$store.commit('layout/SET_LOCALE', { locale: 'fr-fr' })
            } else if (result.dismiss === DismissReason.cancel) {
              Cookie.set('ask_change_country', 'true', { path: '/', expires: 10 })
            }
          }
          if (country === 'BE') {
            const result = await this.$swal({
              title: 'Vous semblez venir de Belgique ?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Aller sur le site 🇧🇪',
              cancelButtonText: 'Non merci',
            })
            if (result.value) {
              this.$store.commit('layout/SET_COUNTRY', country)
              this.$store.commit('layout/SET_LOCALE', { locale: 'fr-be' })
            } else if (result.dismiss === DismissReason.cancel) {
              Cookie.set('ask_change_country', 'true', { path: '/', expires: 10 })
            }
          }
          if (country === 'CH') {
            const result = await this.$swal({
              title: 'Vous semblez venir de Suisse ?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Aller sur le site 🇨🇭',
              cancelButtonText: 'Non merci',
            })
            if (result.value) {
              this.$store.commit('layout/SET_COUNTRY', country)
              this.$store.commit('layout/SET_LOCALE', { locale: 'fr-ch' })
            } else if (result.dismiss === DismissReason.cancel) {
              Cookie.set('ask_change_country', 'true', { path: '/', expires: 10 })
            }
          }
          if (country === 'LU') {
            const result = await this.$swal({
              title: 'Vous semblez venir du Luxembourg ?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Aller sur le site 🇱🇺',
              cancelButtonText: 'Non merci',
            })
            if (result.value) {
              this.$store.commit('layout/SET_COUNTRY', country)
              this.$store.commit('layout/SET_LOCALE', { locale: 'fr-lu' })
            } else if (result.dismiss === DismissReason.cancel) {
              Cookie.set('ask_change_country', 'true', { path: '/', expires: 10 })
            }
          }
        }
      }
    }
  }
}
