import { render, staticRenderFns } from "./default.vue?vue&type=template&id=35ffe170&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=35ffe170&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ffe170",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PromotionalMessage: require('/app/components/alert/PromotionalMessage.vue').default,Navbar: require('/app/components/includes/Navbar.vue').default,AppFooter: require('/app/components/includes/AppFooter.vue').default,CookieBanner: require('/app/components/CookieBanner.vue').default})
