
export default {
  name: 'error-404',
  props: {
    error: {
      type: Object,
      default: () => {},
    }
  },
  head () {
    return {
      title: 'Page Introuvable - Escrime-Occasion.com'
    }
  }
}
