
import { mapGetters } from 'vuex'

export default {
  name: 'Footer',
  data () {
    return {
      siteName: this.$config.siteName,
      year: new Date().getFullYear(),
      socials: this.$config.socials,
      categories: [42, 2, 3, 40, 41, 6, 33, 22],
      questions: [
        /*{
          name: 'Comment bien s\'équiper ?',
          url: 'https://faq.escrime-occasion.com/questions/comment-bien-sequiper-6'
        },
        {
          name: 'Comment entretenir son matériel ?',
          url: 'https://faq.escrime-occasion.com/questions/comment-entretenir-son-materiel-de1e8424/'
        },*/
        {
          name: 'Comment déposer une annonce ?',
          url: 'https://faq.escrime-occasion.com/questions/comment-deposer-une-annonce-4f76c83c/'
        },
        {
          name: 'Que vérifier quand j’achète du matériel ?',
          url: 'https://faq.escrime-occasion.com/questions/que-verifier-quand-jachete-du-materiel-dc4ad981/'
        },
        {
          name: 'À quel prix vendre mon matériel ?',
          url: 'https://faq.escrime-occasion.com/questions/a-quel-prix-vendre-mon-materiel-86763d0c/'
        },
        {
          name: 'Quel matériel je peux vendre ?',
          url: 'https://faq.escrime-occasion.com/questions/quel-materiel-je-peux-vendre-sur-escrime-occasion-ef060775/'
        }
      ]
    }
  },
  computed: {
    // ...mapState('layout', ['brands']),
    ...mapGetters({
      getCategory: 'layout/getCategory',
      brands: 'layout/getBrandsOrdered',
    })
  },
}
