import { UserService, AuthenticationError } from '@/services/user.service'

const state = () => ({
  isAuthenticated: false,
  authenticating: false,
  authenticationErrorCode: 0,
  authenticationError: ''
})

const getters = {
  isAuthenticated: state => {
    return state.isAuthenticated
  },

  authenticationErrorCode: state => {
    return state.authenticationErrorCode
  },

  authenticationError: state => {
    return state.authenticationError
  },

  authenticating: state => {
    return state.authenticating
  }
}

const actions = {
  async login ({ commit, dispatch }, { email, password }) {
    commit('loginRequest')
    try {
      const { userId, logged } = await UserService.login(email, password)
      await dispatch('gtm/setUserProperties', { userId }, { root: true })
      await dispatch('gtm/login', 'password', { root: true })
      commit('loginSuccess')
      return logged
    } catch (e) {
      commit('loginError', { errorCode: e.errorCode, errorMessage: e.message })
      return false;
    }
  },

  async loginFromCookie ({ commit }) {
    commit('loginSuccess')
  },

  // @deprecated
  async checkIfLogged ({ commit }) {
    commit('loginRequest')
    try {
      await UserService.check()
      commit('loginSuccess')
    } catch (e) {
      commit('loginError', { errorCode: e.errorCode, errorMessage: e.message })
    }
  },

  async logout ({ commit, dispatch }) {
    try {
      await UserService.logout()
      commit('logoutSuccess')
      await dispatch('user/clearUser', null, { root: true })
      await dispatch('gtm/setUserProperties', null, { root: true })
      this.$bugsnag.setUser(null)
      try {
        window.OneSignal.push(function () {
          window.OneSignal.removeExternalUserId()
        })
      } catch (e) {
      }
      await this.$router.push(this.localePath('index'))

    } catch (err) {
      alert('Une erreur est survenue')
    }
  }
}

const mutations = {
  loginRequest (state) {
    state.authenticating = true
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },

  loginSuccess (state) {
    state.authenticating = false
    state.isAuthenticated = true
  },

  loginError (state, { errorCode, errorMessage }) {
    state.authenticating = false
    state.isAuthenticated = false
    state.authenticationErrorCode = errorCode
    state.authenticationError = errorMessage
  },

  logoutSuccess (state) {
    state.isAuthenticated = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
