
import Cookie from 'js-cookie'

const COOKIE_NAME = 'bravePopupTip'

export default {
  name: 'BraveAlert',
  data() {
    return {
      hidden: false
    }
  },
  created () {
    this.hidden = Cookie.get(COOKIE_NAME)
  },
  methods: {
    hidePopup () {
      this.hidden = true
      Cookie.set(COOKIE_NAME, 'true', { path: '/', expires: 1 / 12 }) // One hour
      this.$emit('hide')
    }
  }
}
