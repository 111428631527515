import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _52efb4ee = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _3fdcfe1b = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _83dfe29a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _0751b071 = () => interopDefault(import('../pages/account/associated-accounts.vue' /* webpackChunkName: "pages/account/associated-accounts" */))
const _ed3e1cba = () => interopDefault(import('../pages/account/offers.vue' /* webpackChunkName: "pages/account/offers" */))
const _7cfbce40 = () => interopDefault(import('../pages/account/likes.vue' /* webpackChunkName: "pages/account/likes" */))
const _f6a5238c = () => interopDefault(import('../pages/account/info.vue' /* webpackChunkName: "pages/account/info" */))
const _de251c50 = () => interopDefault(import('../pages/account/messages.vue' /* webpackChunkName: "pages/account/messages" */))
const _17fc993e = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _09c50332 = () => interopDefault(import('../pages/account/change-pass.vue' /* webpackChunkName: "pages/account/change-pass" */))
const _09092a72 = () => interopDefault(import('../pages/account/edit-profile.vue' /* webpackChunkName: "pages/account/edit-profile" */))
const _07382da2 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _11c81305 = () => interopDefault(import('../pages/account/wallet.vue' /* webpackChunkName: "pages/account/wallet" */))
const _216372e7 = () => interopDefault(import('../pages/account/offer-edit.vue' /* webpackChunkName: "pages/account/offer-edit" */))
const _9994af48 = () => interopDefault(import('../pages/legal/general-terms-of-use.vue' /* webpackChunkName: "pages/legal/general-terms-of-use" */))
const _3a652342 = () => interopDefault(import('../pages/legal/general-terms-of-sale.vue' /* webpackChunkName: "pages/legal/general-terms-of-sale" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _7bc5ee1a = () => interopDefault(import('../pages/contest.vue' /* webpackChunkName: "pages/contest" */))
const _42b8c2d9 = () => interopDefault(import('../pages/post-offer.vue' /* webpackChunkName: "pages/post-offer" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _33eac2b0 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _126ea239 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _2b4a7806 = () => interopDefault(import('../pages/legal/legal-mention.vue' /* webpackChunkName: "pages/legal/legal-mention" */))
const _43fe2188 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _e64111b4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _41a440d2 = () => interopDefault(import('../pages/legal/privacy-policy.vue' /* webpackChunkName: "pages/legal/privacy-policy" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _63972d04 = () => interopDefault(import('../pages/club/ClubProfile.vue' /* webpackChunkName: "pages/club/ClubProfile" */))
const _6ba8d7f6 = () => interopDefault(import('../pages/g/change-password.vue' /* webpackChunkName: "pages/g/change-password" */))
const _5298642d = () => interopDefault(import('../pages/g/confirm-email.vue' /* webpackChunkName: "pages/g/confirm-email" */))
const _5ff13702 = () => interopDefault(import('../pages/offers/_slug/index.vue' /* webpackChunkName: "pages/offers/_slug/index" */))
const _565cf77e = () => interopDefault(import('../pages/categories/_slug.vue' /* webpackChunkName: "pages/categories/_slug" */))
const _10b800f1 = () => interopDefault(import('../pages/brands/_slug.vue' /* webpackChunkName: "pages/brands/_slug" */))
const _6b891f54 = () => interopDefault(import('../pages/posts/_slug.vue' /* webpackChunkName: "pages/posts/_slug" */))
const _9e6e8956 = () => interopDefault(import('../pages/profile/_username.vue' /* webpackChunkName: "pages/profile/_username" */))
const _3b27e56e = () => interopDefault(import('../pages/offers/_slug/map.vue' /* webpackChunkName: "pages/offers/_slug/map" */))
const _b2d33018 = () => interopDefault(import('../pages/offers/_slug/message.vue' /* webpackChunkName: "pages/offers/_slug/message" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior,

  routes: [{
    path: "/categories/",
    component: _52efb4ee,
    pathToRegexpOptions: {"strict":true},
    name: "categories___fr-fr"
  }, {
    path: "/changer-mon-mot-de-passe/",
    component: _3fdcfe1b,
    pathToRegexpOptions: {"strict":true},
    name: "auth-change-password___fr-fr"
  }, {
    path: "/compte/",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___fr-fr",
    children: [{
      path: "/compte/comptes-associes/",
      component: _0751b071,
      pathToRegexpOptions: {"strict":true},
      name: "account-associated-accounts___fr-fr"
    }, {
      path: "/compte/mes-annonces/",
      component: _ed3e1cba,
      pathToRegexpOptions: {"strict":true},
      name: "account-offers___fr-fr"
    }, {
      path: "/compte/mes-favoris/",
      component: _7cfbce40,
      pathToRegexpOptions: {"strict":true},
      name: "account-likes___fr-fr"
    }, {
      path: "/compte/mes-informations/",
      component: _f6a5238c,
      pathToRegexpOptions: {"strict":true},
      name: "account-info___fr-fr"
    }, {
      path: "/compte/mes-messages/",
      component: _de251c50,
      pathToRegexpOptions: {"strict":true},
      name: "account-messages___fr-fr"
    }, {
      path: "/compte/mes-transactions/",
      component: _17fc993e,
      pathToRegexpOptions: {"strict":true},
      name: "account-transactions___fr-fr"
    }, {
      path: "/compte/modifier-mon-mot-de-passe/",
      component: _09c50332,
      pathToRegexpOptions: {"strict":true},
      name: "account-change-pass___fr-fr"
    }, {
      path: "/compte/mon-profil/",
      component: _09092a72,
      pathToRegexpOptions: {"strict":true},
      name: "account-edit-profile___fr-fr"
    }, {
      path: "/compte/parametres/",
      component: _07382da2,
      pathToRegexpOptions: {"strict":true},
      name: "account-settings___fr-fr"
    }, {
      path: "/compte/portefeuille/",
      component: _11c81305,
      pathToRegexpOptions: {"strict":true},
      name: "account-wallet___fr-fr"
    }, {
      path: "/compte/mes-annonces/editer/",
      component: _216372e7,
      pathToRegexpOptions: {"strict":true},
      name: "account-offer-edit___fr-fr"
    }]
  }, {
    path: "/conditions-generales-d-utilisation/",
    component: _9994af48,
    pathToRegexpOptions: {"strict":true},
    name: "legal-general-terms-of-use___fr-fr"
  }, {
    path: "/conditions-generales-de-vente/",
    component: _3a652342,
    pathToRegexpOptions: {"strict":true},
    name: "legal-general-terms-of-sale___fr-fr"
  }, {
    path: "/connexion/",
    component: _8e1272a8,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___fr-fr"
  }, {
    path: "/contest/",
    component: _7bc5ee1a,
    pathToRegexpOptions: {"strict":true},
    name: "contest___fr-fr"
  }, {
    path: "/deposer-une-annonce/",
    component: _42b8c2d9,
    pathToRegexpOptions: {"strict":true},
    name: "post-offer___fr-fr"
  }, {
    path: "/fr-be/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr-be"
  }, {
    path: "/fr-ch/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr-ch"
  }, {
    path: "/fr-lu/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr-lu"
  }, {
    path: "/inscription/",
    component: _33eac2b0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___fr-fr"
  }, {
    path: "/maintenance/",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance___fr-fr"
  }, {
    path: "/marques/",
    component: _126ea239,
    pathToRegexpOptions: {"strict":true},
    name: "brands___fr-fr"
  }, {
    path: "/mentions-legales/",
    component: _2b4a7806,
    pathToRegexpOptions: {"strict":true},
    name: "legal-legal-mention___fr-fr"
  }, {
    path: "/mot-de-passe-oublie/",
    component: _43fe2188,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___fr-fr"
  }, {
    path: "/nous-contacter/",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___fr-fr"
  }, {
    path: "/politique-de-confidentialite/",
    component: _41a440d2,
    pathToRegexpOptions: {"strict":true},
    name: "legal-privacy-policy___fr-fr"
  }, {
    path: "/recherche/",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___fr-fr"
  }, {
    path: "/club/ClubProfile/",
    component: _63972d04,
    pathToRegexpOptions: {"strict":true},
    name: "club-ClubProfile___fr-fr"
  }, {
    path: "/fr-be/categories/",
    component: _52efb4ee,
    pathToRegexpOptions: {"strict":true},
    name: "categories___fr-be"
  }, {
    path: "/fr-be/changer-mon-mot-de-passe/",
    component: _3fdcfe1b,
    pathToRegexpOptions: {"strict":true},
    name: "auth-change-password___fr-be"
  }, {
    path: "/fr-be/compte/",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___fr-be",
    children: [{
      path: "/fr-be/compte/comptes-associes/",
      component: _0751b071,
      pathToRegexpOptions: {"strict":true},
      name: "account-associated-accounts___fr-be"
    }, {
      path: "/fr-be/compte/mes-annonces/",
      component: _ed3e1cba,
      pathToRegexpOptions: {"strict":true},
      name: "account-offers___fr-be"
    }, {
      path: "/fr-be/compte/mes-favoris/",
      component: _7cfbce40,
      pathToRegexpOptions: {"strict":true},
      name: "account-likes___fr-be"
    }, {
      path: "/fr-be/compte/mes-informations/",
      component: _f6a5238c,
      pathToRegexpOptions: {"strict":true},
      name: "account-info___fr-be"
    }, {
      path: "/fr-be/compte/mes-messages/",
      component: _de251c50,
      pathToRegexpOptions: {"strict":true},
      name: "account-messages___fr-be"
    }, {
      path: "/fr-be/compte/mes-transactions/",
      component: _17fc993e,
      pathToRegexpOptions: {"strict":true},
      name: "account-transactions___fr-be"
    }, {
      path: "/fr-be/compte/modifier-mon-mot-de-passe/",
      component: _09c50332,
      pathToRegexpOptions: {"strict":true},
      name: "account-change-pass___fr-be"
    }, {
      path: "/fr-be/compte/mon-profil/",
      component: _09092a72,
      pathToRegexpOptions: {"strict":true},
      name: "account-edit-profile___fr-be"
    }, {
      path: "/fr-be/compte/parametres/",
      component: _07382da2,
      pathToRegexpOptions: {"strict":true},
      name: "account-settings___fr-be"
    }, {
      path: "/fr-be/compte/portefeuille/",
      component: _11c81305,
      pathToRegexpOptions: {"strict":true},
      name: "account-wallet___fr-be"
    }, {
      path: "/fr-be/compte/mes-annonces/editer/",
      component: _216372e7,
      pathToRegexpOptions: {"strict":true},
      name: "account-offer-edit___fr-be"
    }]
  }, {
    path: "/fr-be/conditions-generales-d-utilisation/",
    component: _9994af48,
    pathToRegexpOptions: {"strict":true},
    name: "legal-general-terms-of-use___fr-be"
  }, {
    path: "/fr-be/conditions-generales-de-vente/",
    component: _3a652342,
    pathToRegexpOptions: {"strict":true},
    name: "legal-general-terms-of-sale___fr-be"
  }, {
    path: "/fr-be/connexion/",
    component: _8e1272a8,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___fr-be"
  }, {
    path: "/fr-be/contest/",
    component: _7bc5ee1a,
    pathToRegexpOptions: {"strict":true},
    name: "contest___fr-be"
  }, {
    path: "/fr-be/deposer-une-annonce/",
    component: _42b8c2d9,
    pathToRegexpOptions: {"strict":true},
    name: "post-offer___fr-be"
  }, {
    path: "/fr-be/inscription/",
    component: _33eac2b0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___fr-be"
  }, {
    path: "/fr-be/maintenance/",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance___fr-be"
  }, {
    path: "/fr-be/marques/",
    component: _126ea239,
    pathToRegexpOptions: {"strict":true},
    name: "brands___fr-be"
  }, {
    path: "/fr-be/mentions-legales/",
    component: _2b4a7806,
    pathToRegexpOptions: {"strict":true},
    name: "legal-legal-mention___fr-be"
  }, {
    path: "/fr-be/mot-de-passe-oublie/",
    component: _43fe2188,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___fr-be"
  }, {
    path: "/fr-be/nous-contacter/",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___fr-be"
  }, {
    path: "/fr-be/politique-de-confidentialite/",
    component: _41a440d2,
    pathToRegexpOptions: {"strict":true},
    name: "legal-privacy-policy___fr-be"
  }, {
    path: "/fr-be/recherche/",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___fr-be"
  }, {
    path: "/fr-ch/categories/",
    component: _52efb4ee,
    pathToRegexpOptions: {"strict":true},
    name: "categories___fr-ch"
  }, {
    path: "/fr-ch/changer-mon-mot-de-passe/",
    component: _3fdcfe1b,
    pathToRegexpOptions: {"strict":true},
    name: "auth-change-password___fr-ch"
  }, {
    path: "/fr-ch/compte/",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___fr-ch",
    children: [{
      path: "/fr-ch/compte/comptes-associes/",
      component: _0751b071,
      pathToRegexpOptions: {"strict":true},
      name: "account-associated-accounts___fr-ch"
    }, {
      path: "/fr-ch/compte/mes-annonces/",
      component: _ed3e1cba,
      pathToRegexpOptions: {"strict":true},
      name: "account-offers___fr-ch"
    }, {
      path: "/fr-ch/compte/mes-favoris/",
      component: _7cfbce40,
      pathToRegexpOptions: {"strict":true},
      name: "account-likes___fr-ch"
    }, {
      path: "/fr-ch/compte/mes-informations/",
      component: _f6a5238c,
      pathToRegexpOptions: {"strict":true},
      name: "account-info___fr-ch"
    }, {
      path: "/fr-ch/compte/mes-messages/",
      component: _de251c50,
      pathToRegexpOptions: {"strict":true},
      name: "account-messages___fr-ch"
    }, {
      path: "/fr-ch/compte/mes-transactions/",
      component: _17fc993e,
      pathToRegexpOptions: {"strict":true},
      name: "account-transactions___fr-ch"
    }, {
      path: "/fr-ch/compte/modifier-mon-mot-de-passe/",
      component: _09c50332,
      pathToRegexpOptions: {"strict":true},
      name: "account-change-pass___fr-ch"
    }, {
      path: "/fr-ch/compte/mon-profil/",
      component: _09092a72,
      pathToRegexpOptions: {"strict":true},
      name: "account-edit-profile___fr-ch"
    }, {
      path: "/fr-ch/compte/parametres/",
      component: _07382da2,
      pathToRegexpOptions: {"strict":true},
      name: "account-settings___fr-ch"
    }, {
      path: "/fr-ch/compte/portefeuille/",
      component: _11c81305,
      pathToRegexpOptions: {"strict":true},
      name: "account-wallet___fr-ch"
    }, {
      path: "/fr-ch/compte/mes-annonces/editer/",
      component: _216372e7,
      pathToRegexpOptions: {"strict":true},
      name: "account-offer-edit___fr-ch"
    }]
  }, {
    path: "/fr-ch/conditions-generales-d-utilisation/",
    component: _9994af48,
    pathToRegexpOptions: {"strict":true},
    name: "legal-general-terms-of-use___fr-ch"
  }, {
    path: "/fr-ch/conditions-generales-de-vente/",
    component: _3a652342,
    pathToRegexpOptions: {"strict":true},
    name: "legal-general-terms-of-sale___fr-ch"
  }, {
    path: "/fr-ch/connexion/",
    component: _8e1272a8,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___fr-ch"
  }, {
    path: "/fr-ch/contest/",
    component: _7bc5ee1a,
    pathToRegexpOptions: {"strict":true},
    name: "contest___fr-ch"
  }, {
    path: "/fr-ch/deposer-une-annonce/",
    component: _42b8c2d9,
    pathToRegexpOptions: {"strict":true},
    name: "post-offer___fr-ch"
  }, {
    path: "/fr-ch/inscription/",
    component: _33eac2b0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___fr-ch"
  }, {
    path: "/fr-ch/maintenance/",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance___fr-ch"
  }, {
    path: "/fr-ch/marques/",
    component: _126ea239,
    pathToRegexpOptions: {"strict":true},
    name: "brands___fr-ch"
  }, {
    path: "/fr-ch/mentions-legales/",
    component: _2b4a7806,
    pathToRegexpOptions: {"strict":true},
    name: "legal-legal-mention___fr-ch"
  }, {
    path: "/fr-ch/mot-de-passe-oublie/",
    component: _43fe2188,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___fr-ch"
  }, {
    path: "/fr-ch/nous-contacter/",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___fr-ch"
  }, {
    path: "/fr-ch/politique-de-confidentialite/",
    component: _41a440d2,
    pathToRegexpOptions: {"strict":true},
    name: "legal-privacy-policy___fr-ch"
  }, {
    path: "/fr-ch/recherche/",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___fr-ch"
  }, {
    path: "/fr-lu/categories/",
    component: _52efb4ee,
    pathToRegexpOptions: {"strict":true},
    name: "categories___fr-lu"
  }, {
    path: "/fr-lu/changer-mon-mot-de-passe/",
    component: _3fdcfe1b,
    pathToRegexpOptions: {"strict":true},
    name: "auth-change-password___fr-lu"
  }, {
    path: "/fr-lu/compte/",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___fr-lu",
    children: [{
      path: "/fr-lu/compte/comptes-associes/",
      component: _0751b071,
      pathToRegexpOptions: {"strict":true},
      name: "account-associated-accounts___fr-lu"
    }, {
      path: "/fr-lu/compte/mes-annonces/",
      component: _ed3e1cba,
      pathToRegexpOptions: {"strict":true},
      name: "account-offers___fr-lu"
    }, {
      path: "/fr-lu/compte/mes-favoris/",
      component: _7cfbce40,
      pathToRegexpOptions: {"strict":true},
      name: "account-likes___fr-lu"
    }, {
      path: "/fr-lu/compte/mes-informations/",
      component: _f6a5238c,
      pathToRegexpOptions: {"strict":true},
      name: "account-info___fr-lu"
    }, {
      path: "/fr-lu/compte/mes-messages/",
      component: _de251c50,
      pathToRegexpOptions: {"strict":true},
      name: "account-messages___fr-lu"
    }, {
      path: "/fr-lu/compte/mes-transactions/",
      component: _17fc993e,
      pathToRegexpOptions: {"strict":true},
      name: "account-transactions___fr-lu"
    }, {
      path: "/fr-lu/compte/modifier-mon-mot-de-passe/",
      component: _09c50332,
      pathToRegexpOptions: {"strict":true},
      name: "account-change-pass___fr-lu"
    }, {
      path: "/fr-lu/compte/mon-profil/",
      component: _09092a72,
      pathToRegexpOptions: {"strict":true},
      name: "account-edit-profile___fr-lu"
    }, {
      path: "/fr-lu/compte/parametres/",
      component: _07382da2,
      pathToRegexpOptions: {"strict":true},
      name: "account-settings___fr-lu"
    }, {
      path: "/fr-lu/compte/portefeuille/",
      component: _11c81305,
      pathToRegexpOptions: {"strict":true},
      name: "account-wallet___fr-lu"
    }, {
      path: "/fr-lu/compte/mes-annonces/editer/",
      component: _216372e7,
      pathToRegexpOptions: {"strict":true},
      name: "account-offer-edit___fr-lu"
    }]
  }, {
    path: "/fr-lu/conditions-generales-d-utilisation/",
    component: _9994af48,
    pathToRegexpOptions: {"strict":true},
    name: "legal-general-terms-of-use___fr-lu"
  }, {
    path: "/fr-lu/conditions-generales-de-vente/",
    component: _3a652342,
    pathToRegexpOptions: {"strict":true},
    name: "legal-general-terms-of-sale___fr-lu"
  }, {
    path: "/fr-lu/connexion/",
    component: _8e1272a8,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___fr-lu"
  }, {
    path: "/fr-lu/contest/",
    component: _7bc5ee1a,
    pathToRegexpOptions: {"strict":true},
    name: "contest___fr-lu"
  }, {
    path: "/fr-lu/deposer-une-annonce/",
    component: _42b8c2d9,
    pathToRegexpOptions: {"strict":true},
    name: "post-offer___fr-lu"
  }, {
    path: "/fr-lu/inscription/",
    component: _33eac2b0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___fr-lu"
  }, {
    path: "/fr-lu/maintenance/",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance___fr-lu"
  }, {
    path: "/fr-lu/marques/",
    component: _126ea239,
    pathToRegexpOptions: {"strict":true},
    name: "brands___fr-lu"
  }, {
    path: "/fr-lu/mentions-legales/",
    component: _2b4a7806,
    pathToRegexpOptions: {"strict":true},
    name: "legal-legal-mention___fr-lu"
  }, {
    path: "/fr-lu/mot-de-passe-oublie/",
    component: _43fe2188,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___fr-lu"
  }, {
    path: "/fr-lu/nous-contacter/",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___fr-lu"
  }, {
    path: "/fr-lu/politique-de-confidentialite/",
    component: _41a440d2,
    pathToRegexpOptions: {"strict":true},
    name: "legal-privacy-policy___fr-lu"
  }, {
    path: "/fr-lu/recherche/",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___fr-lu"
  }, {
    path: "/g/change-password/",
    component: _6ba8d7f6,
    pathToRegexpOptions: {"strict":true},
    name: "g-change-password"
  }, {
    path: "/g/confirm-email/",
    component: _5298642d,
    pathToRegexpOptions: {"strict":true},
    name: "g-confirm-email"
  }, {
    path: "/fr-be/club/ClubProfile/",
    component: _63972d04,
    pathToRegexpOptions: {"strict":true},
    name: "club-ClubProfile___fr-be"
  }, {
    path: "/fr-ch/club/ClubProfile/",
    component: _63972d04,
    pathToRegexpOptions: {"strict":true},
    name: "club-ClubProfile___fr-ch"
  }, {
    path: "/fr-lu/club/ClubProfile/",
    component: _63972d04,
    pathToRegexpOptions: {"strict":true},
    name: "club-ClubProfile___fr-lu"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr-fr"
  }, {
    path: "/fr-be/annonces/:slug/",
    component: _5ff13702,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug___fr-be"
  }, {
    path: "/fr-be/categories/:slug/",
    component: _565cf77e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___fr-be"
  }, {
    path: "/fr-be/marques/:slug/",
    component: _10b800f1,
    pathToRegexpOptions: {"strict":true},
    name: "brands-slug___fr-be"
  }, {
    path: "/fr-be/posts/:slug?/",
    component: _6b891f54,
    pathToRegexpOptions: {"strict":true},
    name: "posts-slug___fr-be"
  }, {
    path: "/fr-be/profil/:username/",
    component: _9e6e8956,
    pathToRegexpOptions: {"strict":true},
    name: "profile-username___fr-be"
  }, {
    path: "/fr-ch/annonces/:slug/",
    component: _5ff13702,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug___fr-ch"
  }, {
    path: "/fr-ch/categories/:slug/",
    component: _565cf77e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___fr-ch"
  }, {
    path: "/fr-ch/marques/:slug/",
    component: _10b800f1,
    pathToRegexpOptions: {"strict":true},
    name: "brands-slug___fr-ch"
  }, {
    path: "/fr-ch/posts/:slug?/",
    component: _6b891f54,
    pathToRegexpOptions: {"strict":true},
    name: "posts-slug___fr-ch"
  }, {
    path: "/fr-ch/profil/:username/",
    component: _9e6e8956,
    pathToRegexpOptions: {"strict":true},
    name: "profile-username___fr-ch"
  }, {
    path: "/fr-lu/annonces/:slug/",
    component: _5ff13702,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug___fr-lu"
  }, {
    path: "/fr-lu/categories/:slug/",
    component: _565cf77e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___fr-lu"
  }, {
    path: "/fr-lu/marques/:slug/",
    component: _10b800f1,
    pathToRegexpOptions: {"strict":true},
    name: "brands-slug___fr-lu"
  }, {
    path: "/fr-lu/posts/:slug?/",
    component: _6b891f54,
    pathToRegexpOptions: {"strict":true},
    name: "posts-slug___fr-lu"
  }, {
    path: "/fr-lu/profil/:username/",
    component: _9e6e8956,
    pathToRegexpOptions: {"strict":true},
    name: "profile-username___fr-lu"
  }, {
    path: "/fr-be/annonces/:slug/map/",
    component: _3b27e56e,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug-map___fr-be"
  }, {
    path: "/fr-be/annonces/:slug/message/",
    component: _b2d33018,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug-message___fr-be"
  }, {
    path: "/fr-ch/annonces/:slug/map/",
    component: _3b27e56e,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug-map___fr-ch"
  }, {
    path: "/fr-ch/annonces/:slug/message/",
    component: _b2d33018,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug-message___fr-ch"
  }, {
    path: "/fr-lu/annonces/:slug/map/",
    component: _3b27e56e,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug-map___fr-lu"
  }, {
    path: "/fr-lu/annonces/:slug/message/",
    component: _b2d33018,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug-message___fr-lu"
  }, {
    path: "/annonces/:slug/",
    component: _5ff13702,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug___fr-fr"
  }, {
    path: "/categories/:slug/",
    component: _565cf77e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___fr-fr"
  }, {
    path: "/marques/:slug/",
    component: _10b800f1,
    pathToRegexpOptions: {"strict":true},
    name: "brands-slug___fr-fr"
  }, {
    path: "/posts/:slug?/",
    component: _6b891f54,
    pathToRegexpOptions: {"strict":true},
    name: "posts-slug___fr-fr"
  }, {
    path: "/profil/:username/",
    component: _9e6e8956,
    pathToRegexpOptions: {"strict":true},
    name: "profile-username___fr-fr"
  }, {
    path: "/annonces/:slug/map/",
    component: _3b27e56e,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug-map___fr-fr"
  }, {
    path: "/annonces/:slug/message/",
    component: _b2d33018,
    pathToRegexpOptions: {"strict":true},
    name: "offers-slug-message___fr-fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
