
import {mapActions, mapGetters} from 'vuex'
import CountrySelectorNavbar from "../CountrySelectorNavbar";

export default {
  name: 'Navbar',
  components: { CountrySelectorNavbar },

  data() {
    return {
      menuOpened: false,
      search: '',
      searchCategory: null,
      menuCat: false,
      menuCat2: null,
    }
  },

  watch: {
    '$route'() {
      if (process.server) return
      document.getElementById('mainNav').classList.remove('show')
      this.closeMenuCat()
      this.menuOpened = false
      this.hideSearchBar()
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      searchBarIsOpened: 'search/searchBarIsOpened',
      categories: 'layout/getCategories',
    }),
  },

  methods: {
    ...mapActions({
      hideSearchBar: 'search/hideSearchBar',
      toggleSearchBar: 'search/toggleSearchBar',
      mergeFilters: 'search/mergeFilters',
      doSearch: 'search/doSearch'
    }),
    openMenu() {
      this.menuOpened = !this.menuOpened
      this.hideSearchBar()
    },

    async showSearchBar() {
      if (this.menuOpened) return
      await this.toggleSearchBar()
      if (this.searchBarIsOpened) this.$refs.search.focus()
    },

    async searchOffer() {
      if (this.search.trim() === '') return
      const routeName = this.$route.name && this.$route.name.split('___')[0]
      const q = this.search.trim()

      if (this.searchCategory) {
        await this.$router.push(this.localePath({ name: 'categories-slug', params: { slug: this.searchCategory }, query: { q, page: 1, order: 'desc' } }))
      } else {
        await this.$router.push(this.localePath({ name: 'search', query: { q, page: 1, order: 'desc' } }))
      }

      if (routeName === 'search') {
        await this.mergeFilters({ q, page: 1 })
        await this.doSearch()
      }
      this.$store.dispatch('gtm/search', q)
      this.hideSearchBar()
      this.search = ''
    },


    openMenuCat() {
      this.menuCat = !this.menuCat
    },
    closeMenuCat() {
      this.menuCat = false
      this.menuCat2 = null
    },
    openMenuSubCat(category) {
      if ((this.$isMobile || this.$isTablet) && this.menuCat2 === category.id) {
        this.menuCat2 = null
      } else {
        this.menuCat2 = category.id
      }
    },
    onCategoryClick(category) {
      if (this.$isMobile || this.$isTablet) {
        if (this.menuCat2 === category.id) this.menuCat2 = null
        else this.menuCat2 = category.id
      } else {
        this.$router.push(this.localePath({ name: 'categories-slug', params: { slug: category.slug } }))
      }
    },
  },
}
