export default function ({ store, redirect, route, app }) {
  if (!store.getters['auth/isAuthenticated']) {
    // if (localStorage) TODO
    //   localStorage.fromUrl = route.path
    return redirect({
      path: app.localePath('auth-login'),
      query: {
        from: route.path
      }
    })
  }
}
