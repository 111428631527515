import Vue from 'vue'
import { API } from '@/services/api.service'

export default async function ({ $config }) {
  window.OneSignal = window.OneSignal || []
  if (window.OneSignal.installServiceWorker) {
    OneSignal.installServiceWorker()
  } else if (navigator.serviceWorker) {
    try {
      await navigator.serviceWorker.register(`/OneSignalSDKWorker.js?appId=${$config.oneSignal.appId}`)
    } catch (e) {
      console.error(e)
      return
    }
  } else {
    return
  }

  window.OneSignal.push(function () {
    // eslint-disable-next-line
    window.OneSignal.init({
      appId: $config.oneSignal.appId,
      safari_web_id: $config.oneSignal.safari_web_id,
      notifyButton: {
        enable: false,
      },
      welcomeNotification: {
        disable: true
      },
      allowLocalhostAsSecureOrigin: process.env.MODE === 'development',
    })

    window.OneSignal.on('subscriptionChange', function (isSubscribed) {
      if (isSubscribed && !this.$isBrave) {
        // The user is subscribed
        //   Either the user subscribed for the first time
        //   Or the user was subscribed -> unsubscribed -> subscribed
        window.OneSignal.getUserId(function (onesignalDeviceId) {
          if (!onesignalDeviceId) return;
          API.post('/users/onesignal', { onesignalDeviceId })
        })
      }
    })
  })

  Vue.prototype.$OneSignal = window.OneSignal
}
