
export default {
  name: 'error-500',
  props: {
    error: {
      type: Object,
      default: () => {
      },
    }
  }
}
