
import error404 from '~/components/error/error404.vue'
import error500 from '~/components/error/error500.vue'

export default {
  props: ['error'],
  layout: 'default',
  head () {
    return {
      meta: [
        { hid: 'robots', name: 'robots', content: 'noindex' }
      ]
    }
  },
  computed: {
    errorPage () {
      if (this.error.statusCode === 404) {
        return error404
      } else {
        return error500
      }
    }
  }
}
