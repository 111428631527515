export default {
  siteName: 'Escrime-Occasion',
  baseUrl: process.env.BASE_URL || 'http://localhost:8080',
  apiURL: process.env.API_URL || 'http://localhost:8090',
  rootDomain: process.env.ROOT_DOMAIN,
  auth: {
    tokenName: 'access_token',
    cookieExpire: 1209600000, // 14 days
    facebook: {
      appId: parseInt(process.env.FB_APP_ID)
    }
  },
  cookie: {
    domain: process.env.COOKIE_DOMAIN || 'localhost',
    secure: process.env.COOKIE_SECURE || false
  },
  cookies: {
    auth: 's.id',
    i18n: 'i18n_redirected',
    consent: 'cookiesConsent'
  },
  offer: {
    perPage: 6
  },
  googleAnalytics: process.env.GOOGLE_ANALYTICS,
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  adsDelay: 6000,
  socials: {
    facebook: 'https://fb.me/EscrimeOccasion',
    twitter: 'https://twitter.com/EscrimeOccasion',
    instagram: 'https://www.instagram.com/escrime_occasion/',
    linkedin: 'https://www.linkedin.com/company/escrime-occasion/'
  },
  leboncoin: {
    regex: /^https:\/\/www.leboncoin.fr\/[a-z_]+\/[0-9]+.htm/
  },
  oneSignal: {
    appId: process.env.ONESIGNAL_APPID,
    safari_web_id: process.env.ONESIGNAL_SAFARI_WEBID
  },
  gtm: {
    id: process.env.GTM_ID
  },
  bugsnag: {
    apiKey: process.env.BUGSNAG_APIKEY
  },
  minio: {
    baseUrl: process.env.MINIO_URL,
    bucket: {
      brands: process.env.BUCKET_BRANDS,
      categories: process.env.BUCKET_CATEGORIES,
    },
  }
}
