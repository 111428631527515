import RouteService from './route.service'

class AuthenticationError extends Error {
  constructor (errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
   **/
  async login (email, password) {
    try {
      const response = await RouteService.login({ email, password })
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    }
  },

  async check() {
    try {
      const response = await RouteService.checkLogged()
      return response.data
    } catch (e) {
      throw new AuthenticationError(e.response.status, e.response.data.message)
    }
  },

  async logout () {
    try {
      await RouteService.logout()
    } catch (e) {
      throw new AuthenticationError(e.response.status, e.response.data.message)
    }
  },

  async fetchUser () {
    try {
      const response = await RouteService.getUserSelf()

      return response.data.user
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.error)
    }
  },

  async fetchUserProfile (username) {
    try {
      const response = await RouteService.getUserProfile(username)
      return response.data.user
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.error)
    }
  },

  async addOfferLike (offerId) {
    const response = await RouteService.addOfferLike(offerId)
    return response.data
  },

  async fetchOffersLiked () {
    try {
      const response = await RouteService.getOffersLiked()
      return response.data
    } catch (err) {
      throw new Error('Failed to remove like')
    }
  },

  async fetchOffersIdLiked () {
    const response = await RouteService.getOffersIdLiked()
    return response.data.offersId
  },

  async sendLinkChangePassword ({ email, recaptchaToken }) {
    try {
      const response = await RouteService.sendForgotPassword({ email, recaptchaToken })
      return response.data
    } catch (e) {
      throw new Error('Failed to send reset password mail')
    }
  }
}

export default UserService

export { UserService, AuthenticationError }
