const state = () => ({
  info: null,
  success: null,
  error: null,
  infoVisible: false,
  successVisible: false,
  errorVisible: false
})

const getters = {
  infoIsVisible: state => {
    return state.infoVisible
  },

  successIsVisible: state => {
    return state.successVisible
  },

  errorIsVisible: state => {
    return state.errorVisible
  },

  getInfoMessage: state => {
    return state.info
  },

  getSuccessMessage: state => {
    return state.success
  },

  getErrorMessage: state => {
    return state.error
  }
}

const actions = {
  pushInfo ({ commit }, { msg, time = 5000 }) {
    commit('SET_INFO', msg)
    commit('SHOW_INFO')
    setTimeout(() => {
      commit('SET_INFO', null)
      commit('SHOW_INFO', false)
    }, time)
  },

  pushSuccess ({ commit }, { msg, time = 5000 }) {
    commit('SET_SUCCESS', msg)
    commit('SHOW_SUCCESS')
    setTimeout(() => {
      commit('SET_SUCCESS', null)
      commit('SHOW_SUCCESS', false)
    }, time)
  },

  pushError ({ commit }, { msg, time = 5000 }) {
    commit('SET_ERROR', msg)
    commit('SHOW_ERROR')
    setTimeout(() => {
      commit('SET_ERROR', null)
      commit('SHOW_ERROR', false)
    }, time)
  },

  hideAll ({ commit }) {
    commit('SHOW_INFO', false)
    commit('SHOW_SUCCESS', false)
    commit('SHOW_ERROR', false)
  }
}

const mutations = {
  SET_INFO (state, info) {
    state.info = info
  },

  SET_SUCCESS (state, success) {
    state.success = success
  },

  SET_ERROR (state, error) {
    state.error = error
  },

  SHOW_INFO (state, infoVisible = true) {
    state.infoVisible = infoVisible
  },

  SHOW_SUCCESS (state, successVisible = true) {
    state.successVisible = successVisible
  },

  SHOW_ERROR (state, errorVisible = true) {
    state.errorVisible = errorVisible
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
