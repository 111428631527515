import { API } from '@/services/api.service'
import { localize, localeChanged } from 'vee-validate'

const state = () => ({
  categories: [],
  brands: [],
  countries: [],
  languages: [],
  currentLanguage: null,
  currentCountry: null,
  currentLocale: null,
  // currency: '€'
})

const getters = {
  getCategories: state => {
    return state.categories
  },
  getFlattedCategories: (state) => {
    return state.categories.reduce((acc, category) => {
      if (category.depends) {
        acc.push(...category.depends)
      }
      const { name, slug, id, id2, order } = category
      acc.push({
        name, slug, id, id2, order
      })
      return acc
    }, [])
  },
  getCategory: (state) => id => {
    const categories = state.categories.reduce((acc, category, index, arr) => {
      const { name, slug, id, id2 } = category
      return [...acc, { name, slug, id, id2 }, ...category.depends]
    }, [])
    return categories.find(c => c.id === id) || {}
  },
  getCountries: state => {
    return state.countries
  },
  getLanguages: state => {
    return state.languages
  },
  getBrands: state => {
    return state.brands
  },
  getCurrentCountry: state => {
    return state.countries.find(c => c.isoCode === state.currentCountry)
  },
  getCurrentCountryId: state => {
    return this.getCurrentCountry(state).id
  },
  getBrandsOrdered: state => {
    return state.brands
      .filter(brand => brand.offerCount > 0)
      .sort((a,b) => b.offerCount - a.offerCount)
      .sort((a,b) => {
        if(a.name < b.name) { return -1 }
        if(a.name > b.name) { return 1 }
        return 0
      })
  }
}

const actions = {
  async setupLocale ({ commit }) {
    commit('SET_COUNTRY', this.$i18n.localeProperties.countryIsoCode)
    commit('SET_LANG', this.$i18n.localeProperties.lang) // Useless ?
    commit('SET_LOCALE', { locale: this.$i18n.locale, setup: true })
  },
  async fetchCategories ({ commit }) {
    try {
      const response = await API.get('/categories')
      commit('SET_CATEGORIES', response.data.categories)
    } catch (e) {
      console.error(e)
    }
  },
  async fetchBrands ({ commit }) {
    try {
      const response = await API.get('/brands?withOfferCount=true')
      commit('SET_BRANDS', response.data.brands)
    } catch (e) {
      console.error(e)
    }
  },
  async fetchCountries ({ commit }) {
    try {
      const response = await API.get('/countries')
      commit('SET_COUNTRIES', response.data.countries)
    } catch (e) {
      console.error(e)
    }
  },
  async fetchLanguages ({ commit }) {
    try {
      const response = await API.get('/languages')
      commit('SET_LANGUAGES', response.data.languages)
    } catch (e) {
      console.error(e)
    }
  },
}

const mutations = {
  SET_CATEGORIES (state, categories) {
    state.categories = categories
  },
  SET_BRANDS (state, brands) {
    state.brands = brands
  },
  SET_COUNTRIES (state, countries) {
    state.countries = countries
  },
  SET_LANGUAGES (state, languages) {
    state.languages = languages
  },
  SET_LANG (state, lang) {
    state.currentLanguage = lang
  },
  SET_COUNTRY (state, country) {
    state.currentCountry = country
  },
  async SET_LOCALE (state, { locale, setup = false }) {
    state.currentLocale = locale

    // Change i18n locale
    if (!setup) {
      await this.$i18n.setLocale(locale)
    }

    const lang = this.$i18n.localeProperties.lang
    const isoCode = this.$i18n.localeProperties.iso
    const shortLang = lang.split('-')[0]

    // Change axios Accept-Language
    this.$axios.setHeader('Accept-Language', isoCode)

    // Change vee-validate language
    localize(shortLang)
    localeChanged()

    // Change dayjs language
    this.$dayjs.locale(lang)

    if (!setup) {
      await Promise.all([
        this.dispatch('layout/fetchCategories'),
        this.dispatch('layout/fetchBrands'),
      ])
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
