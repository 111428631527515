import axios from 'axios'

class Request {
  constructor (baseURL, withCredentials, timeout = 20000) {
    this.instance = axios.create()
    this.instance.defaults.baseURL = baseURL
    this.instance.defaults.withCredentials = withCredentials
    this.instance.defaults.timeout = timeout
  }

  get (url, config) {
    return this.instance.get(url, config)
  }

  post (url, data, config) {
    return this.instance.post(url, data, config)
  }

  put (url, data, config) {
    return this.instance.put(url, data, config)
  }

  delete (url, data, config) {
    return this.instance.delete(url, { ...config, data })
  }
}

export default Request
