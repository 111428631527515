
export default {
  name: 'CookieBanner',
  computed: {
    show () {
      return this.$store.state.cookiesConsent
    }
  },
  methods: {
    accept () {
      this.$store.dispatch('acceptCookies')
    },
    reject () {
      this.$store.dispatch('rejectCookies')
    }
  }
}
