const mapItemGA = (offer) => {
  const { id, title, brand, category, price } = offer
  return {
    item_id: id,
    item_name: title,
    item_brand: brand,
    item_category: category && category.slug, // Or name
    price: Number(price),
    currency: 'EUR'
  }
}

const mapItemFB = (offer) => {
  const { id, title, brand, category, price } = offer
  return {
    content_name: title,
    content_category: category.slug,
    content_ids: [id],
    value: Number(price)
  }
}

export {
  mapItemGA,
  mapItemFB
}
