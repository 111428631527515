export default {
  language: "fr",
  common: {
    yes: "oui",
    Yes: "@.capitalize:common.yes",
    no: "non",
    No: "@.capitalize:common.no",
    loading: "Chargement...",
    save: "Sauvegarder",
    optional: "Optionnel",
    select: "Sélectionner",
    arms: "armes",
    foil: "fleuret",
    Foil: "@.capitalize:common.foil",
    epee: "épée",
    Epee: "@.capitalize:common.epee",
    sabre: "sabre",
    Sabre: "@.capitalize:common.sabre",
    category: "catégorie",
    categories: "catégories",
    size: "taille",
    standard: "norme",
    age: "âge",
    sex: "sexe",
    condition: "état",
    brand: "marque",
    hand: "main",
    city: "ville",
    postalDelivery: "envoi postal",
    noBrand: "Autre",
    alert: {
      errorOccurred: "Une erreur est survenue"
    }
  },
  breadcrumbs: {
    home: "Accueil",
    categories: "Catégories",
    brands: "Marques"
  },
  enum: {
    age: {
      youngChild: "Bébé",
      child: "Enfant",
      teenager: "Adolescent",
      adult: "Adulte"
    },
    condition: {
      bad: "Mauvais",
      satisfactory: "Satisfaisant",
      good: "Bon",
      veryGood: "Très bon",
      new: "Neuf",
      newWithoutTag: "Neuf sans étiquette",
      newWithTag: "Neuf avec étiquette"
    },
    hand: {
      left: "Gauche",
      right: "Droite"
    },
    offerStatus: {
      draft: "Brouillon",
      pending: "En attente",
      active: "En ligne",
      refused: "Refusée",
      suspended: "Suspendue"
    },
    sex: {
      unisex: "Unisexe",
      male: "Homme",
      female: "Femme"
    }
  },
  pages: {
    home: {
      seo: {
        title: "Escrime-Occasion.com - Achetez, vendez ou échangez votre équipement d’escrime de seconde main",
        metaTitle: "Escrime-Occasion.com - Achetez, vendez ou échangez votre équipement d’escrime de seconde main",
        metaDescription: "Site gratuit d’annonces qui facilite les échanges de matériel entre particuliers. Achetez, vendez ou échangez votre matériel d'occasion sur Escrime-Occasion.",
      },
      header: {
        title: "Trouvez votre matériel d'escrime&nbsp;!",
        subTitle: "Parcourez les annonces déposées par d’autres escrimeurs et trouvez votre matériel à moindre coût, pour le fleuret, l’épée ou le sabre.",
        button: {
          first: "Voir les annonces",
          second: "S'inscrire"
        }
      },
      brandsList: {
        title: "Retrouvez de nombreuses marques",
      },
      lastOffers: {
        title: "Les dernières annonces en France",
        button: {
          more: "Voir plus d'annonces",
          empty: "Soyez le premier à déposer une annonce en France !"
        },
      },
      partners: {
        title: "Ils nous font confiance",
      },
      search: {
        title: "Que recherchez-vous ?",
        input: "Mots clés",
        button: "Je recherche",
      },
      medias: {
        title: "La presse parle de nous !",
      },
    },
    search: {
      seo: {
        title: "Recherche - Escrime-Occasion.com",
        metaTitle: "Recherche - Escrime-Occasion.com",
        metaDescription: "Trouvez du matériel d’escrime pas cher en consultant toutes nos annonces : tenues, masques, armes (fleuret, épée, sabre), chaussures, équipements de salle...",
      },
      title: "Résultats pour « {query} »",
      titleNoQuery: "Annonces"
    },
    category: {
      seo: {
        title: "Annonces {category} d'occasion - Escrime-Occasion.com",
        metaTitle: "Annonces {category} d'occasion - Escrime-Occasion.com",
        metaDescription: "Retrouvez toutes les annonces de la catégorie {category}",
      },
      title: "Annonces {category}"
    },
    brand: {
      seo: {
        title: "Annonces {brand} d'occasion - Escrime-Occasion.com",
        metaTitle: "Annonces {brand} d'occasion - Escrime-Occasion.com",
        metaDescription: "Retrouvez toutes les annonces de la marque {brand}",
      },
      title: "{brand}"
    },
    register: {
      seo: {
        title: "S'inscrire - Escrime-Occasion.com",
        metaTitle: "S'inscrire - Escrime-Occasion.com",
        metaDescription: "Créez votre compte gratuitement sur Escrime-Occasion pour vendre ou acheter du matériel d’escrime.",
      },
      title: "Inscription",
      connectWith: {
        google: "Continuer avec Google",
        facebook: "Continuer avec Facebook",
      },
      form: {
        email: {
          label: "E-mail",
          placeholder: "ex : email@domain.com",
        },
        password: {
          label: "Mot de passe",
          placeholder: "min 8 caractères",
        },
        lastname: {
          label: "Nom",
          placeholder: "Votre nom",
        },
        firstname: {
          label: "Prénom",
          placeholder: "Votre prénom",
        },
        cgu: "Accepter les Conditions générales d'utilisation et les Conditions générales de vente",
        submit: "Créer mon compte"
      },
      buttonAlreadyRegistered: "J'ai déjà un compte !",
      confirmCancel: "Voulez-vous vraiment annuler votre inscription ?",
      alert: {
        confirmEmail: "Un email vient de vous être envoyé pour confirmer votre adresse email."
      }
    },
    login: {
      seo: {
        title: "Se connecter - Escrime-Occasion.com",
        metaTitle: "Se connecter - Escrime-Occasion.com",
        metaDescription: "Accéder à mon compte personnel pour vendre ou acheter du matériel d’escrime d’occasion.",
      },
      title: "Connexion",
      connectWith: {
        google: "Continuer avec Google",
        facebook: "Continuer avec Facebook",
      },
      form: {
        email: {
          label: "E-mail",
          placeholder: "Votre adresse e-mail",
        },
        password: {
          label: "Mot de passe",
          placeholder: "Votre mot de passe",
        },
        submit: "Se connecter",
      },
      forgotPassword: "Mot de passe oublié ?",
      buttonNotRegistered: "Je n'ai pas de compte",
      alert: {
        validEmail: "Vous venez de valider l'adresse email {email}, vous pouvez maintenant vous connecter",
        needLogin: "Veuillez d'abord vous connecter pour accéder à cette page",
        invalidSession: "Session invalide, merci de vous réauthentifier",
        alreadyAnAccountWithEmail: "Vous possedez déjà un compte avec cette adresse email, veuillez d'abord vous connectez à votre compte pour associer un compte externe.",
        alreadyAnAccountWithFacebook: "Vous possedez déjà un compte avec votre compte Facebook.",
        alreadyAnAccountWithGoogle: "Vous possedez déjà un compte avec votre compte Google.",
        needEmailFacebook: "Vous devez avoir une adresse email sur votre compte Facebook.",
        validEmailExpire:"Ce lien a expiré",
        validEmailInvalid: "Ce lien n'est pas valide"
      }
    },
    forgotPassword: {
      seo: {
        title: "Mot de passe oublié - Escrime-Occasion.com",
        metaTitle: "Mot de passe oublié - Escrime-Occasion.com",
        metaDescription: "Réinitialiser mon mot de passe pour accéder à mon compte Escrime-Occasion",
      },
      title: "Mot de passe oublié",
      form: {
        email: {
          label: "E-mail",
          placeholder: "Votre e-mail"
        },
        submit: {
          text: "Recevoir un lien de récupération",
        }
      }
    },
    changePassword: {
      seo: {
        title: "Définir un nouveau mot de passe - Escrime-Occasion.com",
        metaTitle: "Définir un nouveau mot de passe - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Définir un nouveau mot de passe",
      form: {
        password: {
          label: "Nouveau mot de passe",
          placeholder: "Saisissez votre nouveau mot de passe"
        },
        confirmPass: {
          label: "Confirmation du mot de passe",
          placeholder: "Confirmez votre mot de passe",
        },
        submit: {
          text: "Modifier mon mot de passe",
        },
      }
    },
    userProfile: {
      seo: {
        title: "Profil de {username} - Escrime-Occasion.com",
        metaTitle: "Profil de {username} - Escrime-Occasion.com",
        metaDescription: "Consultez le profil de {username}",
      },
      memberSince: "Membre depuis {date}",
      memberLocation: "{city} ({country})",
      memberOffers: "0 annonce en ligne | 1 annonce en ligne | {count} annonces en ligne",
      memberLastSignIn: "Vu {date}",
      info: "Pensez à regarder le calendrier des compétitions ! C’est l’occasion de se rencontrer et d’échanger du matériel."
    },
    accountEditProfile: {
      seo: {
        title: "Mon profil - Escrime-Occasion.com",
        metaTitle: "Mon profil - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Mon profil",
      subtitle: "",
      form: {
        username: {
          label: "Nom d'utilisateur"
        },
        arms: {
          label: "Mes armes"
        },
        country: {
          label: "Pays",
          select: "Sélectionner un pays",
        },
        city: {
          label: "Ville"
        },
        submit: "Enregistrer"
      },
      section: {
        1: "Informations publiques",
        2: "Localisation de mes annonces"
      },
      noLocation: "Veuillez définir une localisation pour vos annonces",
      linkProfile: "Consulter mon profil public"
    },
    accountInfo: {
      seo: {
        title: "Mes informations - Escrime-Occasion.com",
        metaTitle: "Mes informations - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Mes informations",
      subtitle: "",
      section: {
        1: "Informations personnelles",
      },
      form: {
        lastname: {
          label: "Nom"
        },
        firstname: {
          label: "Prénom"
        },
        email: {
          label: "E-mail",
          verified: "Adresse email vérifié ✅"
        },
        phone: {
          label: "Téléphone",
          placeholder: "Numéro de téléphone"
        },
        showPhone: {
          label: "Afficher mon numéro de téléphone sur mes annonces"
        },
        nationality: {
          label: "Nationalité",
        },
        country: {
          label: "Mon pays"
        },
        address: {
          label: "Mon adresse",
          placeholder: "Votre adresse"
        },
        submit: "Enregistrer"
      }
    },
    accountMyPassword: {
      seo: {
        title: "Modifier mon mot de passe - Escrime-Occasion.com",
        metaTitle: "Modifier mon mot de passe - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Modifier mon mot de passe",
      subtitle: "",
      form: {
        password: {
          label: "Nouveau mot de passe",
          placeholder: "Saisissez votre nouveau mot de passe"
        },
        confirmPass: {
          label: "Confirmation du mot de passe",
          placeholder: "Confirmez votre mot de passe",
        },
        submit: {
          edit: "Modifier mon mot de passe",
          new: "Définir un mot de passe"
        }
      }
    },
    accountAssociatedAccount: {
      seo: {
        title: "Mes comptes associés - Escrime-Occasion.com",
        metaTitle: "Mes comptes associés - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Mes comptes associés",
      subtitle: "Connectez-vous facilement à Escrime-Occasion",
      section: {
        1: "Associer ou dissocier un compte externe"
      },
      btnAssociate: "Associer mon compte {provider}",
      btnDissociate: "Dissocier mon compte {provider}",
    },
    accountMyOffers: {
      seo: {
        title: "Mes annonces - Escrime-Occasion.com",
        metaTitle: "Mes annonces - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Mes annonces",
      subtitle: "",
      noOffers: "Vous n'avez pas d'annonces",
      btnPostOffer: "Déposer une annonce",
      card: {
        see: "Voir",
        preview: "Prévisualiser",
        edit: "Editer",
        continue: "Reprendre",
        delete: "Supprimer"
      }
    },
    accountSettings: {
      seo: {
        title: "Paramètres - Escrime-Occasion.com",
        metaTitle: "Paramètres - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Paramètres",
      subtitle: "",
      notifications: {
        title: "Notifications",
        subTitle: "Réglez les notifications que vous souhaitez recevoir",
        validOffers: "Validation de mes annonces :",
        newMessages: "Nouveaux messages :",
        email: "Email",
        push: "Notification push"
      },
      display: {
        title: "Affichage",
        subTitle: "Préférences d'affichage du site",
        offersCountry: "Pays des annonces :",
        allCountries: "Tous les pays",
        onlyCountry: 'uniquement {country}',
        language: "Langue :"
      },
      confidentiality: {
        title: "Confidentialité",
        subTitle: "Contrôlez vos données",
        downloadData: "Télécharger mes données",
        deleteAccount: "Supprimer mon compte"
      }
    },
    accountLikes: {
      seo: {
        title: "Mes favoris - Escrime-Occasion.com",
        metaTitle: "Mes favoris - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Mes favoris",
      subtitle: "",
      noLike: "Vous n'avez pas d'annonces en favoris",
      remove: "Retirer",
      see: "Voir l'annonce"
    },
    accountMessages: {
      seo: {
        title: "Mes messages - Escrime-Occasion.com",
        metaTitle: "Mes messages - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Mes messages",
      subtitle: "",
      noMessage: "Vous n'avez aucun message.",
      noConversationSelected: "Pas de conversation sélectionnée",
      sellBy: "Vendu par",
      warnMsg: "Veuillez privilégier vos échanges par cette messagerie afin de ne pas divulguer vos informations personnelles (email, téléphone, etc.)",
      inputPlaceholder: "Ecrivez votre message ...",
      price: "Gratuit | {count} {currency} | {count} {currency}",
      you: "vous",
      buttons: {
        send: "Envoyer",
        sending: "Envoi en cours ..."
      }
    },
    offerEdit: {
      seo: {
        title: "Editer mon annonce - Escrime-Occasion.com",
        metaTitle: "Editer mon annonce - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Editer mon annonce",
      toasts: {
        // needTitle: "Veuillez indiquer au moins un titre",
        // offerSaved: "Votre annonce a été sauvegardée",
        confirmSubmit: {
          title: "Voulez-vous soumettre à nouveau votre annonce ?",
          confirm: "Oui soumettre",
          cancel: "Annuler"
        },
        submitted: {
          title: "Félicitation !",
          content: "Votre annonce a bien été enregistrée. Elle est en cours de validation et sera visible par tous sous 24h."
        },
        // failedToSave: "Impossible de sauvegarder votre annonce",
        // failedToSubmit: "Impossible de déposer votre annonce",
      },
      cannotEditTitle: "Vous ne pouvez pas modifier le titre une fois l'annonce validé",
      cannotEditCategory: "Vous ne pouvez pas modifier la catégorie une fois l'annonce validé",
      cannotDeleteMainPicture: "La photo principale ne peut être supprimée",
      pictureEditInfo: "Cliquez et faites glisser pour réorganiser les photos",
      mainPicture: "Photo principale",
      form: {
        arms: {
          label: "Arme(s) concernée(s)",
        },
        title: {
          label: "Titre de mon annonce",
          placeholder: "ex : Masque de sabre"
        },
        category: {
          label: "Catégorie"
        },
        description: {
          label: "Description",
          placeholder: "ex : Très peu utilisé, taille grand, ..."
        },
        age: {
          label: "Age",
        },
        sex: {
          label: "Sexe",
        },
        hand: {
          label: "Main",
        },
        size: {
          label: "Taille",
          placeholder: "ex : S / Lame 2 / 115"
        },
        standard: {
          label: "Norme",
        },
        condition: {
          label: "Etat",
        },
        brand: {
          label: "Marque",
          noBrand: "Aucune marque"
        },
        price: {
          label: "Je le vends au prix de",
          name: "Prix",
          append: "euros (€)"
        },
        location: {
          label: "Localisation",
          placeholder: "Votre adresse",
          info: "IMPORTANT: Votre adresse exacte ne sera jamais rendue publique, elle est utilisée uniquement dans le but de localiser l'annonce dans un périmètre."
        },
        pictures: {
          label: "Photo(s)",
        },
        delivery: {
          label: "Livraison",
          toggle: "Je peux envoyer mon article par la poste"
        },
        save: "Sauvegarder les modifications",
        submit: "Soumettre à nouveau mon annonce"
      }
    },
    postOffer: {
      seo: {
        title: "Déposer une annonce - Escrime-Occasion.com",
        metaTitle: "Déposer une annonce - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Déposer une annnonce",
      quitWithoutSave: "Etes-vous sûr de vouloir annuler le dépôt de votre annonce ?",
      steps: {
        1: "Annonce",
        2: "Localisation",
        3: "Options",
        4: "Finalisation",
        5: "Paiement"
      },
      stepInfo: {
        onlyOneItem: "Merci d'indiquer seulement un article par annonce",
        buttonImportLeboncoin: "Importer mon annonce depuis leboncoin",
        arms: {
          label: "Arme(s) concernée(s)",
        },
        title: {
          label: "Titre de mon annonce",
          placeholder: "ex : Masque de sabre"
        },
        category: {
          label: "Catégorie"
        },
        description: {
          label: "Description",
          placeholder: "ex : Très peu utilisé, taille grand, ..."
        },
        age: {
          label: "Age",
        },
        sex: {
          label: "Sexe",
        },
        hand: {
          label: "Main",
        },
        size: {
          label: "Taille",
          placeholder: "ex : S / Lame 2 / 115"
        },
        standard: {
          label: "Norme",
        },
        condition: {
          label: "Etat",
        },
        brand: {
          label: "Marque",
          noBrand: "Aucune marque"
        },
        price: {
          label: "Je le vends au prix de",
          name: "Prix",
          append: "euros (€)"
        },
        location: {
          label: "Localisation",
          placeholder: "Votre adresse",
          info: "IMPORTANT: Votre adresse exacte ne sera jamais rendue publique, elle est utilisée uniquement dans le but de localiser l'annonce dans un périmètre."
        },
        pictures: {
          label: "Photo(s)",
        },
        delivery: {
          label: "Livraison",
          toggle: "Je peux envoyer mon article par la poste"
        },
        next: "Suivant",
      },
      stepOptions: {
        noOptionsAvailable: "Aucune option n'est actuellement disponible.",
        prev: "Précédent",
        next: "Suivant"
      },
      stepReview: {
        title: "Récapitulatif de l'annonce",
        edit: "modifier",
        prev: "Précédent",
        confirm: "Soumettre mon annonce"
      },
      saveAndContinueLater: "Sauvegarder et continuer plus tard",
      toasts: {
        needTitle: "Veuillez indiquer au moins un titre",
        offerSaved: "Votre annonce a été sauvegardée",
        offerSubmitted: {
          title: "Félicitation !",
          content: "Votre annonce a bien été enregistrée. Elle est en cours de validation et sera visible par tous sous 24h."
        },
        failedToSave: "Impossible de sauvegarder votre annonce",
        failedToSubmit: "Impossible de déposer votre annonce",
      }
    },
    offerDetail: {
      seo: {
        title: "{offerTitle} - Escrime-Occasion.com",
        metaTitle: "{offerTitle} - Escrime-Occasion.com",
        metaDescription: "",
      },
      buttons: {
        like: "Ajouter aux favoris",
        unlike: "Retirer des favoris",
        edit: "Modifier",
        call: "Appeler",
        sendMessage: "Envoyer un message",
      },
      notApproved: "⚠ Cette annonce n'a pas encore été approuvée.",
      price: "Gratuit | {count} {currency} | {count} {currency}",
      sellBy: "Vendu par",
      shareOn: "Partager sur :",
      map: {
        title: "Où se trouve l'annonce ?",
        onlyHandDelivery: "Le produit est disponible uniquement en remise en main propre dans la ville suivante : <b>{city} ({country})</b>",
        delivery: "Le produit est disponible en remise en main propre dans la ville suivante : <b>{city} ({country})</b> ou en livraison."
      },
      similar: "Annonces similaires"
    },
    offerMessage: {
      seo: {
        title: "{offerTitle} - Escrime-Occasion.com",
        metaTitle: "{offerTitle} - Escrime-Occasion.com",
        metaDescription: "",
      },
      form: {
        alertInfo: "<b>Attention</b> : Méfiez-vous des propositions trop alléchantes et des prix trop bas. Assurez-vous de ne pas être victime d’une tentative d’escroquerie.",
        title: "Envoyer un message à {username}",
        message: {
          label: "",
          placeholder: "Votre message"
        },
        submit: "Envoyer"
      },
      resume: {
        title: "Résumé de l'annonce",
        price: "Gratuit | {count} {currency} | {count} {currency}",
        sellBy: "vendu par",
        description: "Description",
        criteria: "Critères",
        location: "Localisation",
        noLocation: "Non communiquée"
      }
    },
    allCategories: {
      seo: {
        title: "Explorez les annonces par catégorie - Escrime-Occasion.com",
        metaTitle: "Explorez les annonces par catégorie - Escrime-Occasion.com",
        metaDescription: "Découvrez toutes les catégories d'escrime-occasion. Explorez les catégories tendances telles que Vestes, Pantalons, Chaussures, Masques, Armes, Sacs & Housses, Enrouleurs et beaucoup d'autres !",
      },
      title: "Explorez les annonces par catégorie"
    },
    allBrands: {
      seo: {
        title: "Explorez les annonces par marque - Escrime-Occasion.com",
        metaTitle: "Explorez les annonces par marque - Escrime-Occasion.com",
        metaDescription: "Découvrez toutes les marques disponibles sur escrime-occasion",
      },
      title: "Explorez les annonces par marque"
    },
    legalNotice: {
      seo: {
        title: "Mentions légales - Escrime-Occasion.com",
        metaTitle: "Mentions légales - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Mentions légales"
    },
    privacyPolicy: {
      seo: {
        title: "Politique de confidentialité - Escrime-Occasion.com",
        metaTitle: "Politique de confidentialité - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Politique de confidentialité"
    },
    termsOfUse: {
      seo: {
        title: "Conditions générales d'utilisation - Escrime-Occasion.com",
        metaTitle: "Conditions générales d'utilisation - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Conditions générales d'utilisation"
    },
    termsOfSale: {
      seo: {
        title: "Conditions générales de vente - Escrime-Occasion.com",
        metaTitle: "Conditions générales de vente - Escrime-Occasion.com",
        metaDescription: "",
      },
      title: "Conditions générales de vente"
    },
    contact: {
      seo: {
        title: "Nous contacter - Escrime-Occasion.com",
        metaTitle: "Nous contacter - Escrime-Occasion.com",
        metaDescription: "Une question, un problème technique, une suggestion, un signalement ? L’équipe Escrime-Occasion est là pour vous répondre !",
      },
      title: "Contacter Escrime-Occasion",
      subTitle: "Nous contacter",
      description: "N’hésitez pas à nous contacter pour toute demande, nous serons heureux de vous répondre.",
      form: {
        object: {
          label: "Objet",
          values: {
            askInformation: "Demande d'information",
            technicalProblem: "Problème technique",
            suggestion: "Suggestion",
            reporting: "Signalement",
            other: "Autre"
          }
        },
        lastname: {
          label: "Nom"
        },
        firstname: {
          label: "Prénom"
        },
        email: {
          label: "E-mail",
          placeholder: "ex : email@domain.com"
        },
        phone: {
          label: "Téléphone",
          placeholder: "ex : +33611223344"
        },
        message: {
          label: "Message",
          placeholder: "50 caractères minimums"
        },
        submit: "Envoyer"
      },
      alert: {
        success: "Nous avons bien reçu votre message ! Nous vous répondrons au plus vite.",
        error: "Erreur: le message n'a pas pu être envoyé !"
      }
    },
    notFound: {
      title: "Page introuvable",
      buttonHome: "Retour à la page d'accueil",
      buttonPostOffer: "Déposer une annonce"
    },
    maintenance: {
      title: "Une maintenance est en cours ..."
    }
  },
  navbar: {
    home: "Accueil",
    offers: "Catégories",
    postOffer: "Déposer une annonce",
    brands: "Marques",
    contact: "Contact",
    login: "Se connecter",
    register: "S'inscrire",
    account: "Mon compte",
    searchPlaceholder: "ex : Fleuret lame 5",
    selectCategory: "Toutes catégories"
  },
  navbarAccount: {
    welcome: "Bonjour {firstname},",
    profile: "Voir mon profil",
    editProfile: "Mon profil",
    information: "Mes informations",
    myPassword: "Mon mot de passe",
    associatedAccounts: "Comptes associés",
    myOffers: "Mes annonces",
    settings: "Paramètres",
    likes: "Favoris",
    messages: "Messages",
    logout: "Se déconnecter",
  },
  searchBlock: {
    selectCategory: "Toutes catégories",
    selectBrand: "Toutes marques",
    searchKeywords: "Mots clés",
    location: {
      placeholder: "Choisir un emplacement",
      noResult: "Aucun résultat ne correspond.",
      nearMe: "📍 Autour de moi (50km)",
      world: "🌍 Monde entier",
      allFR: "🇫🇷 Toute la France",
      allBE: "🇧🇪 Toute la Belgique",
      allCH: "🇨🇭 Toute la Suisse",
      allPT: "🇵🇹 Tout le Portugal",
      allES: "🇪🇸 Toute l'Espagne",
      allLU: "🇱🇺 Tout le Luxembourg",
    },
    minPrice: "Prix minimum",
    maxPrice: "Prix maximum",
    submitButton: "Je recherche !",
    results: "0 résultat | 1 résultat | {count} résultats",
    filterBy: "Trier par :",
    filters: {
      relevance: "Pertinence",
      priceDesc: "Prix décroissant",
      priceAsc: "Prix croissant",
      publishedAt: "Le plus récent",
    },
    noResult: "Aucune annonce ne correspond à votre recherche...",
    noResultBtn: "Déposer une annonce !"
  },
  offerCard: {
    price: "Gratuit | {count} {currency} | {count} {currency}",
  },
  footer: {
    title: "ESCRIME-OCCASION",
    description: "Site d'annonces dédié à la vente de vêtements et de matériel d'escrime d'occasion.",
    about: {
      title: "A propos",
      legalNotice: "Mentions légales",
      generalTermsOfUse: "Conditions générales d'utilisation",
      generalTermsOfSale: "Conditions générales de vente",
      privacyPolicy: "Politique de confidentialité",
      contact: "Nous contacter",
      faq: "Foire aux questions"
    },
    categories: {
      title: "Catégories",
    },
    brands: {
      title: "Marques",
    },
    faq: {
      title: "FAQ",
    },
    copyright: "Tous droits réservés"
  },
  cookiesConsent: {
    text: "🍪 En poursuivant votre navigation sur ce site sans modifier les paramètres relatifs aux cookies dans votre navigateur, vous acceptez l’installation de cookies sur votre terminal. Ceux-ci sont utilisés pour améliorer les fonctionnalités et l’utilisation de notre site internet, mais aussi à des fins publicitaires",
    readMore: "En savoir plus",
    accept: "Accepter",
  }
}
