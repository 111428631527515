import Vue from 'vue'
import { extend, localize, configure, ValidationObserver, ValidationProvider, localeChanged } from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import en from 'vee-validate/dist/locale/en.json'
import de from 'vee-validate/dist/locale/de.json'
import it from 'vee-validate/dist/locale/it.json'
import ca from 'vee-validate/dist/locale/ca.json'
import { required, email, min_value, max_value, min, max, confirmed, image, ext } from 'vee-validate/dist/rules'
import { acceptTerms, tel, username } from '@/forms/rules'

localize({ fr, en, de, it, ca })

const rules = { required, email, min_value, max_value, min, max, confirmed, image, ext }
for (const rule in rules) {
  extend(rule, {
    ...rules[rule],
  })
}

extend('acceptTerms', acceptTerms)
extend('tel', tel)
extend('username', username)

const configVeeValidate = {
  classes: {
    valid: '',
    invalid: 'is-invalid'
  },
  mode: 'eager'
}
configure(configVeeValidate)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default (ctx, inject) => {
  const shortLang = ctx.i18n.localeProperties.lang.split('-')[0]
  localize(shortLang)
  localeChanged()
}
